import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SpecialFlag } from '../../../classes/SpecialFlag';
import { HttpService } from '../../../services/http.service';
import { CONSTS } from '../../../constants';
import { LoaderService } from '../../../services/loader.service';
import { EditSpecialFlagFormData } from '../components/special-flags/edit-special-flag-modal/classes/EditSpecialFlagFormData';

@Injectable({
  providedIn: 'root',
})
export class SpecialFlagService {
  constructor(private httpService: HttpService, private loaderService: LoaderService) {}

  getSpecialFlagList(): Observable<SpecialFlag[]> {
    return this.httpService.get(CONSTS.API_END_POINT.SPECIAL_FLAGS);
  }

  deleteFlag(id: number): Observable<unknown> {
    this.loaderService.showLoader();
    return this.httpService.delete(`${CONSTS.API_END_POINT.SPECIAL_FLAGS}/${id}`);
  }

  updateFlag(flag: EditSpecialFlagFormData): Observable<SpecialFlag> {
    this.loaderService.showLoader();

    const formData = this.createFormDataFromFlag(flag);

    return this.httpService.post(`${CONSTS.API_END_POINT.SPECIAL_FLAGS}/${flag.id}`, formData);
  }

  createFlag(flag: EditSpecialFlagFormData): Observable<SpecialFlag> {
    this.loaderService.showLoader();

    const formData = this.createFormDataFromFlag(flag);

    return this.httpService.post(CONSTS.API_END_POINT.SPECIAL_FLAGS, formData);
  }

  saveFlagsForParkingSlot(slotId: number, flags: SpecialFlag[]): Observable<unknown> {
    return this.httpService.put(`${CONSTS.API_END_POINT.PARKING_SLOT}/${slotId}`, { flags: flags });
  }

  private createFormDataFromFlag(flag: EditSpecialFlagFormData): FormData {
    const formData: FormData = new FormData();

    if (flag.icon) {
      formData.append('icon', flag.icon, flag.icon?.name);
    }
    formData.append('name', flag.name);

    return formData;
  }
}
