<div *ngIf="reservationStatus && !reservationStatus.final"
     class="outer-tooltip floor-{{ floorNumber }} d-flex align-items-end tooltip-garage-user"
     [style.top]="tooltipData.top"
     [style.left]="tooltipData.left"
     [style.opacity]="tooltipData.opacity"
     [style.display]="tooltipData.display">

    <div class="inner-tooltip w-100">
        <div class="bottom-div py-2">
            <ng-container *ngIf="reservationStatus.exist">
                <div class="hour">
                    <div *ngIf="!reservationStatus.expiration"
                         class="no-timer">
                        Reserved<br/>
                        for you
                    </div>

                    <app-count-down-timer
                        *ngIf="reservationStatus.expiration"
                        (onTimeEnd)="timeEndEvent()"
                        [endDate]="tooltipData.endTime">
                    </app-count-down-timer>
                </div>

                <div *ngIf="
                    reservationStatus &&
                    reservationStatus.expiration &&
                    !reservationStatus.expiration_extended &&
                    !reservationStatus.expired
                 "
                     class="refresh-icon">
                    <img (click)="extendEvent()"
                         title="extend reservation"
                         src="/assets/images/icon_RestartCounter.svg"
                         alt="restart counter"
                    />
                </div>
            </ng-container>
        </div>

        <div class="triangle"></div>
    </div>
</div>

<div *ngIf="reservationStatus && reservationStatus.final"
     class="outer-parked-tooltip floor-{{ floorNumber }}"
     [style.top]="tooltipData.top"
     [style.left]="tooltipData.left"
     [style.opacity]="tooltipData.opacity"
     [style.display]="tooltipData.display">

    <img [src]="CONSTS.ICON_URL.SHOW_MY_RESERVATION" alt="show my reservation"/>
</div>
