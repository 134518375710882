import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTS } from '../../../../constants';
import { DeviceService } from '../../../../services/device.service';

@Component({
  selector: 'app-floor-selector',
  templateUrl: './floor-selector.component.html',
  styleUrls: ['./floor-selector.component.scss'],
})
export class FloorSelectorComponent implements OnInit {
  @Input() floorNumber: number;
  @Input() freePlaceCount: number;
  @Input() showLoader: boolean;

  isMobile: boolean;

  constructor(private router: Router, private deviceService: DeviceService) {}

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
  }

  selectFloor(number: number) {
    this.router.navigate([CONSTS.PAGE_URL.FLOOR[number]]);
  }

  disableClick(event: Event) {
    event.stopPropagation();
  }
}
