import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Weekday } from '../../classes/weekday';
import { CONSTS } from '../../../../../../constants';
import moment from 'moment';
import { ParkPlaceSelectGarageUserService } from '../../../../services/park-place-select-garage-user.service';
import { Router } from '@angular/router';
import { DeviceService } from '../../../../../../services/device.service';

@Component({
  selector: 'app-release-slot-date-picker-modal',
  templateUrl: './release-slot-date-picker-modal.component.html',
  styleUrls: ['./release-slot-date-picker-modal.component.scss'],
})
export class ReleaseSlotDatePickerModalComponent implements OnInit {
  @Input() data: { token: string };

  token: string;
  selectedDates: Date[] = [];

  startDate: Date;
  endDate: Date;

  weeksSwitched = 0;
  weekLimitReached = false;
  isMobile: boolean;

  weekDays: Weekday[] = [
    {
      label: 'Monday',
      value: new Date(),
    },
    {
      label: 'Tuesday',
      value: new Date(),
    },
    {
      label: 'Wednesday',
      value: new Date(),
    },
    {
      label: 'Thursday',
      value: new Date(),
    },
    {
      label: 'Friday',
      value: new Date(),
    },
  ];

  readonly WEEKEND_INDEXES = [0, 6];
  readonly WEEK_LIMIT = CONSTS.RELEASE_PARKING_SPOT_WEEK_LIMIT;

  constructor(
    public activeModal: NgbActiveModal,
    private parkPlaceSelectService: ParkPlaceSelectGarageUserService,
    private router: Router,
    private deviceService: DeviceService
  ) {}

  ngOnInit() {
    this.token = this.data.token;
    this.isMobile = this.deviceService.isMobile();

    this.setDateInterval();
    this.setWeekDayValues();

    this.getSelectedDates();
  }

  private setDateInterval(): void {
    const currentDay = new Date();

    this.startDate = moment().startOf('isoWeek').toDate();
    this.endDate = moment().endOf('isoWeek').subtract(2, 'days').toDate();

    if (this.WEEKEND_INDEXES.includes(currentDay.getDay())) {
      this.startDate = moment(this.startDate).add(1, 'weeks').toDate();
      this.endDate = moment(this.endDate).add(1, 'weeks').toDate();
    }
  }

  private getSelectedDates(): void {
    this.parkPlaceSelectService.getReleaseDates(this.token).subscribe({
      next: (res) => {
        res.forEach((date) => {
          const selectedDate = new Date(date.date);
          const actualDate = new Date();

          if (selectedDate >= actualDate || selectedDate.toDateString() === actualDate.toDateString()) {
            this.selectedDates.push(new Date(date.date));
          }
        });
      },
    });
  }

  getCheckboxType(day: Weekday) {
    let isSelected = false;

    this.selectedDates.forEach((date) => {
      if (this.areDatesEqual(date, day.value)) {
        isSelected = true;
      }
    });

    return isSelected ? CONSTS.ICON_URL.CHECKBOX_CHECKED : CONSTS.ICON_URL.CHECKBOX_UNCHECKED;
  }

  selectDay(day: Weekday) {
    let isSelected = false;
    let selectedIndex: number;

    for (let index = 0; index < this.selectedDates.length; index++) {
      if (this.areDatesEqual(this.selectedDates[index], day.value)) {
        isSelected = true;
        selectedIndex = index;
        break;
      }
    }

    if (isSelected) {
      this.selectedDates.splice(selectedIndex, 1);
    } else {
      this.selectedDates.push(day.value);
    }
  }

  switchWeek(direction: 'prev' | 'next') {
    if (direction === 'prev') {
      this.weeksSwitched--;
      this.startDate = moment(this.startDate).subtract(7, 'days').toDate();
      this.endDate = moment(this.endDate).subtract(7, 'days').toDate();
    } else {
      this.weeksSwitched++;
      this.startDate = moment(this.startDate).add(7, 'days').toDate();
      this.endDate = moment(this.endDate).add(7, 'days').toDate();
    }

    this.weekLimitReached = this.weeksSwitched === this.WEEK_LIMIT;

    this.setWeekDayValues();
  }

  private setWeekDayValues(): void {
    this.weekDays = this.weekDays.map((weekDay, index) => {
      return {
        ...weekDay,
        value: moment(this.startDate).add(index, 'days').toDate(),
      };
    });
  }

  get isCurrentWeek(): boolean {
    return this.startDate <= new Date();
  }

  saveReleaseDates(): void {
    const formattedDates = this.selectedDates.map((date) => {
      return moment(date).format(CONSTS.DATE_FORMAT);
    });

    this.parkPlaceSelectService.setReleaseDates(this.token, formattedDates).subscribe({
      next: () => {
        this.activeModal.close();
        this.router.navigate([CONSTS.PAGE_URL.LOGIN]);
      },
    });
  }

  private areDatesEqual(first: Date, second: Date): boolean {
    return moment(first).format(CONSTS.DATE_FORMAT) === moment(second).format(CONSTS.DATE_FORMAT);
  }

  cancelRelease() {
    this.activeModal.dismiss();
    this.router.navigate([CONSTS.PAGE_URL.LOGIN]);
  }
}
