<app-modal-header
    [whiteCloseIcon]="true"
    [closeWhiteIcon]="addFavouriteView ? 'arrow_back.svg' : 'close_white.svg'"
    [customCloseAction]="addFavouriteView"
    (closeClicked)="setAddView(false)"
    [activeModal]="activeModal">
</app-modal-header>

<div class="modal-body favourite-modal-body">
    <div class="image"><img alt="favourites" [src]="CONSTS.ICON_URL.FAVOURITES"/></div>
    <div class="favourite-title" [class.mobile]="isMobile">
        {{!addFavouriteView ? "Your favourite spots" : "Choose your favourite spot"}}

        <div class="f-title-icon"
             [attr.placement]="isMobile ? 'left' : 'top'"
             tooltip="The spots you've added to your favourites marked with the user icon can be removed only"
             triggers="mouseenter:mouseleave:click">
            <img alt="info" [src]="CONSTS.ICON_URL.INFO_BLUE"/>
        </div>
    </div>

    <div *ngIf="!addFavouriteView" class="favourite-list">
        <app-loader
            *ngIf="loading"
            class="d-flex align-items-center justify-content-center h-100 w-100"
            [isFullScreen]="false"
            [showLoader]="true">
        </app-loader>

        <ng-container *ngIf="!loading">
            <div *ngIf="favourites.length === 0" class="pt-3">
                Your favourite list is empty.
            </div>

            <div *ngFor="let favourite of favourites"
                 class="favourite-element"
                 (click)="selectFavourite(favourite)"
                 [class.not-available]="favourite.parking_slot.reservation"
                 [class.selected]="isFavouriteSelected(favourite)">

                <div class="favourite-element-content"
                     [class.mobile]="isMobile"
                     [class.has-close]="favourite.manually_added">

                    <img *ngIf="favourite.manually_added"
                         alt="user"
                         class="f-user-icon"
                         [attr.title]=getSpotTitle(favourite)
                         [src]="getUserIconUrl(favourite)"/>

                    <span [attr.title]=getSpotTitle(favourite)>
                        {{ favourite.parking_slot.slot_number }}
                    </span>

                    <div *ngIf="favourite.manually_added" class="d-flex">
                        <div class="flag-list d-flex">
                            <div
                                *ngFor="let flag of favourite.parking_slot.flags?.slice(0, this.HIDE_SPECIAL_FLAGS_LIMIT)"
                                [attr.title]="flag.name"
                                class="mx-1">

                                <img class="inverted-image flag-icon" [src]="flag.iconUrl" alt="flag_icon">
                            </div>

                            <img
                                *ngIf="favourite.parking_slot.flags?.length > this.HIDE_SPECIAL_FLAGS_LIMIT"
                                class="flag-icon"
                                [tooltip]="flagListTooltip"
                                [containerClass]="'flag-icon-list-tooltip'"
                                [src]="CONSTS.ICON_URL.MORE"
                                alt="more_icon">
                        </div>

                        <span class="app-close-icon align-self-center ml-2"
                              (click)="removeFavourite($event, favourite)">

                            <img alt="close"
                                 src="/assets/images/close_white.svg"
                                 title="Remove spot from your manually favourite list"/>

                        </span>
                    </div>
                </div>

                <ng-template #flagListTooltip>
                    <div class="d-flex p-2">
                        <div *ngFor="let flag of favourite.parking_slot.flags?.slice(3)" class="mx-1">
                            <img class="flag-icon" [src]="flag.iconUrl" alt="flag_icon">
                        </div>
                    </div>
                </ng-template>

            </div>
        </ng-container>
    </div>

    <div *ngIf="addFavouriteView" class="fav-spot-input-container">
        <div class="fav-spot-input text-input">
            <input name="text"
                   [(ngModel)]="newSpot"
                   type="text"
                   class="un-bordered-dark-input"
                   placeholder="Enter Parking spot number"
            />
        </div>
        <div class="text-or">OR</div>
        <div class="text-pick link-hover" (click)="pickPlaceFromMap()">
            I pick from the map
        </div>
    </div>
</div>
<div class="modal-footer">

    <ng-container *ngIf="!addFavouriteView">
        <div *ngIf="!selectedFavourite"
             (click)="setAddView(true)"
             class="fav-modal-button-link cian-blue-bg">
            <a class="modal-button">
                ADD NEW
            </a>
        </div>
        <div *ngIf="selectedFavourite"
             (click)="reserveSpot()"
             class="fav-modal-button-link cian-blue-bg">
            <a class="modal-button">
                RESERVE SELECTED SPOT
            </a>
        </div>
    </ng-container>

    <div *ngIf="addFavouriteView"
         [class.disabled]="!newSpot"
         (click)="addFavourite()"
         class="fav-modal-button-link cian-blue-bg">
        <a class="modal-button">
            ADD TO FAVOURITES
        </a>
    </div>
</div>
