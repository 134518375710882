import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';

declare const $: JQueryStatic;

@Directive({
  selector: '[appAutoScale]',
})
export class AutoScaleDirective implements AfterViewInit {
  @Input() appAutoScale: number;

  windowElement: JQuery<HTMLElement>;

  constructor(private elementRef: ElementRef) {
    this.windowElement = $('body');
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    setTimeout(() => {
      this.resizeElement();
    });
  }

  ngAfterViewInit(): void {
    this.resizeElement();
  }

  resizeElement() {
    let innerWidth = this.windowElement.innerWidth();

    if (innerWidth < this.appAutoScale) {
      this.elementRef.nativeElement.style.transform =
        'scale(' +
        innerWidth / this.appAutoScale +
        ') translate(0,' +
        (1 - innerWidth / this.appAutoScale) * 50 +
        '%)';
    } else {
      this.elementRef.nativeElement.style.transform = 'scale(1)';
    }
  }
}
