import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent {
  @Input() whiteCloseIcon = false;
  @Input() activeModal: NgbActiveModal;

  @Input() closeBlackIcon = 'close.svg';
  @Input() closeWhiteIcon = 'close_white.svg';

  @Input() customCloseAction = false;
  @Output() closeClicked = new EventEmitter<boolean>();

  closeIconClick() {
    if (!this.customCloseAction) {
      this.activeModal.dismiss();
    } else {
      this.closeClicked.emit(true);
    }
  }
}
