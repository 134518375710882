import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CONSTS } from '../../../../constants';
import { CommonComponent } from '../common/common.component';
import * as moment from 'moment';
import { LogCategory } from './classes/LogCategory';
import { DateTimeRangeEvent } from '../../../shared/components/date-time-picker/classes/DateTimePickerEvent';
import { LogService } from '../../services/log.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LogsComponent extends CommonComponent implements OnInit, AfterViewInit {
  filterValue: string = '';

  logCategories: LogCategory[];
  selectedCategory: LogCategory;

  fromDate: string;
  toDate: string;

  readonly MAX_LOG_RANGE_MONTH = 3;

  constructor(private logService: LogService, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.getLogCategories();
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  private getLogCategories() {
    return this.logService.getLogCategories().subscribe({
      next: (categories) => {
        this.logCategories = categories;
        this.selectLogCategory(this.logCategories[0]);
      },
    });
  }

  get csvName() {
    return `logs_${this.fromDate}_${this.toDate}`;
  }

  selectLogCategory(category: LogCategory) {
    if (category.id !== this.selectedCategory?.id) {
      this.selectedCategory = category;
    }
  }

  selectDate(event: DateTimeRangeEvent) {
    this.fromDate = moment(event.date).format(CONSTS.DATE_FORMAT);
    this.toDate = moment(event.toDate).format(CONSTS.DATE_FORMAT);
  }
}
