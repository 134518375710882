import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import { CONSTS } from '../../../../constants';

declare const $: JQueryStatic;

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DateTimePickerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('timePicker', { static: true }) timePicker: ElementRef;

  @Input() dateTime: string;
  @Input() initDate: string;
  @Input() minDate: Date = new Date();
  @Input() isError: boolean;

  @Output() dateTimeChange = new EventEmitter<string>();
  @Output() dateChange = new EventEmitter<string>();

  time: string = null;
  date: string;
  selectedDate: Date;

  timePickerJquery: JQuery<HTMLElement>;

  bsConfig: Partial<BsDatepickerConfig> = { containerClass: 'theme-default' };

  ngOnInit() {
    if (this.dateTime) {
      this.selectedDate = new Date(this.dateTime);

      const dateParts = this.dateTime.split(' ');
      this.time = dateParts[1];
    } else if (this.initDate) {
      this.selectedDate = new Date(this.initDate);
    } else {
      this.selectedDate = this.minDate;
    }
  }

  ngAfterViewInit() {
    this.timePickerJquery = $(this.timePicker.nativeElement);

    this.timePickerJquery.timepicker({ timeFormat: 'H:i' });
    this.timePickerJquery.on('change', () => {
      this.time = this.timePickerJquery.val() as string;
      this.updateDateTimeValue();
    });
    if (this.dateTime) {
      this.timePickerJquery.timepicker('setTime', this.time);
    }
  }

  onDateValueChanged(selectedDate: Date) {
    this.date = moment(selectedDate).format(CONSTS.DATE_FORMAT);

    this.dateChange.emit(this.date);
    this.updateDateTimeValue();
  }

  updateDateTimeValue() {
    if (this.time !== null) {
      setTimeout(() => {
        this.dateTimeChange.emit(this.date + ' ' + this.time);
      });
    }
  }

  showtimePicker() {
    this.timePickerJquery.timepicker('show');
  }

  ngOnDestroy() {
    $(this.timePicker.nativeElement).timepicker('remove');
  }

  updateDate(date: Date) {
    this.selectedDate = date;
  }

  getDate() {
    return this.selectedDate;
  }
}
