<div class="modal-header">
    <div class="close-icon"
         aria-label="Close"
         title="close modal"
         (click)="closeIconClick()"
    >
        <img class="modal-close-icon"
             *ngIf="!whiteCloseIcon"
             [src]="'/assets/images/' + closeBlackIcon"
             alt="close"
        />
        <img class="modal-close-icon"
             *ngIf="whiteCloseIcon"
             [src]="'/assets/images/' + closeWhiteIcon"
             alt="close"
        />
    </div>
</div>
