import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { APP_ROUTES } from './app.routes';
import { LoginModule } from './modules/login/login.module';
import { AppConfig } from './app.config';
import { AuthModule } from './modules/auth/auth.module';
import { HttpService } from './services/http.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FloorSelectModule } from './modules/floor-select/floor-select.module';
import { ParkPlaceSelectModule } from './modules/park-place-select/park-place-select.module';
import { SharedModule } from './modules/shared/shared.module';
import { ModalService } from './services/modal.service';
import { LoaderService } from './services/loader.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AlertsTabModule } from './modules/alerts-tab/alerts-tab.module';
import { WsInterceptor } from './interceptor/ws-interceptor';
import { DeviceService } from './services/device.service';
import { AdminModule } from './modules/admin/admin.module';
import { FooterCommonComponent } from './components/footer/footer-common/footer-common.component';
import { FooterAdminComponent } from './components/footer/footer-admin/footer-admin.component';
import { FooterLoginComponent } from './components/footer/footer-login/footer-login.component';
import { FooterGarageUserDeviceChooserComponent } from './components/footer/footer-garage-user/footer-garage-user-device-chooser/footer-garage-user-device-chooser.component';
import { FooterGarageUserDesktopComponent } from './components/footer/footer-garage-user/footer-garage-user-device-chooser/footer-garage-user-desktop.component';
import { FooterGarageUserMobileComponent } from './components/footer/footer-garage-user/footer-garage-user-device-chooser/footer-garage-user-mobile.component';
import { FooterGarageUserComponent } from './components/footer/footer-garage-user/footer-garage-user.component';
import { AsyncPipe, CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { FirebaseMessagingService } from './services/firebase-messaging.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

export function initConfig(appConfig: AppConfig) {
  return () => appConfig.load();
}

@NgModule({
  declarations: [
    AppComponent,

    // FOOTER
    FooterCommonComponent,
    FooterAdminComponent,
    FooterLoginComponent,
    FooterGarageUserDeviceChooserComponent,
    FooterGarageUserDesktopComponent,
    FooterGarageUserMobileComponent,
    FooterGarageUserComponent,
    FooterGarageUserDeviceChooserComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AuthModule,
    LoginModule,
    FloorSelectModule,
    ParkPlaceSelectModule,
    SharedModule,
    HttpClientModule,
    AlertsTabModule,
    NgbModule,
    RouterModule.forRoot(APP_ROUTES),
    AdminModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production,
    }),
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    TooltipModule,
  ],
  providers: [
    HttpService,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfig],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WsInterceptor,
      multi: true,
    },
    DeviceService,
    ModalService,
    LoaderService,
    FirebaseMessagingService,
    AsyncPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
