<div class="gantt-chart-container">
    <div #xAxisDiv class="x-axis-div"></div>
    <div #xAxisDivCover class="x-axis-div-cover"></div>

    <div #xAxisFloorDiv class="floor-div floor-1-div"></div>
    <div #xAxisFloorTextDiv class="floor-text-div floor-1-text-div">Floor -1</div>

    <div #yAxisDiv class="y-axis-div"></div>

    <div #floor2Div class="floor-div floor-2-div"></div>
    <div #floor2TextDiv class="floor-text-div floor-2-text-div">Floor -2</div>

    <div #currentTimeLineDiv class="current-time-line"></div>

    <drag-scroll
        [snap-disabled]="true"
        class="gantt-content w-100 h-100">
        <div #ganttChart></div>
    </drag-scroll>
</div>
