import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminMenuService } from '../../../services/admin-menu.service';
import { User } from '../../../../../classes/User';
import { CONSTS } from '../../../../../constants';
import { UserEditFormData } from '../../../classes/UserEditFormData';
import { UserEditModalData } from './classes/UserEditModalData';

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./edit-user-modal.component.scss'],
})
export class EditUserModalComponent implements OnInit {
  @Input() data: UserEditModalData;

  tmpUser: UserEditFormData;
  CONSTS = CONSTS;

  isVisitor: boolean = false;
  isGarageUser: boolean = false;
  editMode: boolean;
  saveClicked: boolean = false;

  constructor(public activeModal: NgbActiveModal, private adminMenuService: AdminMenuService) {}

  ngOnInit() {
    this.editMode = this.data.editMode;

    if (this.editMode) {
      this.tmpUser = new UserEditFormData(Object.assign({}, this.data.user));
      this.tmpUser.licence_plate_numbers = this.data.user.licence_plate_numbers.slice();
      this.tmpUser.roles = this.data.user.roles.slice();
    } else {
      this.tmpUser = new UserEditFormData();
      this.tmpUser.licence_plate_numbers = [];
      this.tmpUser.roles = [];
      this.tmpUser.flags = [];
    }

    for (let curRole of this.tmpUser.roles) {
      if (curRole.slug === CONSTS.ROLE.VISITOR) {
        this.isVisitor = true;
        break;
      } else if (curRole.slug === CONSTS.ROLE.USER) {
        this.isGarageUser = true;
        break;
      }
    }
  }

  // need to wait for response to not close modal if error happened
  saveUser(formValid: boolean) {
    this.saveClicked = true;

    this.checkPrivateSlotNumber();

    if (formValid && !this.isFormDataInvalid()) {
      if (this.editMode) {
        this.adminMenuService.updateUser(this.tmpUser).subscribe((user: User) => {
          this.activeModal.close(user);
        });
      } else {
        this.adminMenuService.createUser(this.tmpUser).subscribe((user: User) => {
          this.activeModal.close(user);
        });
      }
    }
  }

  isEmpty(str: string) {
    return !str || 0 === str.length;
  }

  isFormDataInvalid() {
    return (
      (!this.isVisitor && this.isEmpty(this.tmpUser.email)) ||
      (this.isGarageUser && this.isEmpty(this.tmpUser.card_number)) ||
      ((this.isGarageUser || this.isVisitor) && this.tmpUser.licence_plate_numbers.length === 0) ||
      this.isEmpty(this.tmpUser.name) ||
      this.isEmpty(this.tmpUser.phone) ||
      this.tmpUser.roles.length === 0
    );
  }

  private checkPrivateSlotNumber(): void {
    if (!this.tmpUser.private_parking_slot_number) {
      this.tmpUser.assistant_email = null;
    }
  }
}
