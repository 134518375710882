import { LicencePlateNumber } from './LicencePlateNumber';
import { Role } from './Role';
import { SpecialFlag } from './SpecialFlag';

export class User {
  id: number;
  email: string;
  name: string;
  phone: string;

  in_garage?: boolean;
  bike?: boolean;
  licence_plate_numbers?: Array<LicencePlateNumber>;
  roles?: Array<Role>;
  flags?: SpecialFlag[];

  status: boolean;
  card_number?: string;
  role?: string;
  licence_plate_number?: string; // user on admin-reservation-modal
  reservations_count?: number;
  auto_created?: boolean;
  password_is_temporary?: boolean;
  popup?: string;

  restricted_at: string;
  banned: boolean;

  assistant_email?: string;
  private_parking_slot_number?: number;
}
