import { Component } from '@angular/core';
import { FooterGarageUserComponent } from '../footer-garage-user.component';

@Component({
  selector: 'app-footer-garage-user-desktop',
  templateUrl: '../footer-garage-user-desktop.component.html',
  styleUrls: ['../footer-garage-user.component.scss'],
})
export class FooterGarageUserDesktopComponent extends FooterGarageUserComponent {
  mode = 'DESKTOP';
}
