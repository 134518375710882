<div class="date-time-picker">
  <span class="date-input">
    <div class="clickable-element" (click)="dp.toggle()"></div>
    <input [ngClass]="{ 'has-error': time && isError }"
           class="date-picker form-control"
           bsDatepicker
           [bsConfig]="bsConfig"
           [minDate]="minDate"
           #dp="bsDatepicker"
           (bsValueChange)="onDateValueChanged($event)"
           [(bsValue)]="selectedDate"
           [disabled]="true"
           type="text"
    />
  </span>
    <div class="time-picker-div">
        <div class="clickable-element" (click)="showtimePicker()"></div>
        <input [ngClass]="{ 'has-error': time && isError }"
               #timePicker
               disabled
               placeholder="time"
               class="form-control time-picker"
               type="text"
        />
    </div>
</div>
