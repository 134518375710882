import { Component } from '@angular/core';
import { CONSTS } from '../../../../constants';
import { UserFormData } from '../../classes/userFormData';
import { AuthService } from '../../../auth/auth.service';
import { LoaderService } from '../../../../services/loader.service';
import { Router } from '@angular/router';
import { ModalService } from '../../../../services/modal.service';
import { LoginBaseComponent } from '../login-base/login-base.component';
import { FirebaseMessagingService } from '../../../../services/firebase-messaging.service';

@Component({
  selector: 'app-login-user-form',
  templateUrl: './login-user-form.component.html',
  styleUrls: ['./login-user-form.component.scss'],
})
export class LoginUserFormComponent extends LoginBaseComponent<UserFormData> {
  endpoint = CONSTS.API_END_POINT.USER_LOGIN;

  formData: UserFormData = {
    email: '',
    password: '',
  };

  protected readonly CONSTS = CONSTS;

  constructor(
    protected messagingService: FirebaseMessagingService,
    protected authService: AuthService,
    protected loaderService: LoaderService,
    protected router: Router,
    protected modalService: ModalService
  ) {
    super(messagingService, authService, loaderService, router, modalService);
    this.formData = new UserFormData();
  }

  userLogin() {
    this.loaderService.showLoader();
    this.login();
  }
}
