<div *ngIf="tooltipData.flags?.length || tooltipData.private_user"
     class="outer-tooltip floor-{{ floorNumber }} d-flex align-items-end tooltip-garage-user"
     [style.top]="tooltipData.top"
     [style.left]="tooltipData.left"
     [style.opacity]="tooltipData.opacity"
     [style.display]="tooltipData.display">

    <div class="inner-tooltip">
        <div *ngIf="tooltipData.flags || tooltipData.private_user" class="top-div overflow-ellipsis">

            <div *ngIf="tooltipData.private_user">
                <ng-container *ngIf="tooltipData.private_user.id === currentUser.id">
                    <div class="reserved-for-title mb-1">Private user:</div>
                    {{tooltipData.private_user.name}} <br/>
                    {{tooltipData.private_user.phone}}
                </ng-container>

                <ng-container *ngIf="!(tooltipData.private_user.id === currentUser.id)">
                    Reserved place
                </ng-container>
            </div>

            <div *ngIf="tooltipData.flags">
                <div class="d-flex tooltip-flags">
                    <div *ngFor="let flag of tooltipData.flags">
                        <img class="flag-icon mr-1" [title]="flag.name" [src]="flag.iconUrl" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div class="bottom-div py-2"></div>

        <div class="triangle"></div>
    </div>
</div>
