import { Component } from '@angular/core';
import { CONSTS } from '../../../../constants';
import { AdminFormData } from '../../classes/adminFormData';
import { AuthService } from '../../../auth/auth.service';
import { LoaderService } from '../../../../services/loader.service';
import { Router } from '@angular/router';
import { ModalService } from '../../../../services/modal.service';
import { LoginBaseComponent } from '../login-base/login-base.component';
import { FirebaseMessagingService } from '../../../../services/firebase-messaging.service';

@Component({
  selector: 'app-login-admin-form',
  templateUrl: './login-admin-form.component.html',
  styleUrls: ['./login-admin-form.component.scss'],
})
export class LoginAdminFormComponent extends LoginBaseComponent<AdminFormData> {
  endpoint = CONSTS.API_END_POINT.ADMIN_LOGIN;

  formData: AdminFormData = {
    email: '',
    password: '',
  };

  protected readonly CONSTS = CONSTS;

  constructor(
    protected messagingService: FirebaseMessagingService,
    protected authService: AuthService,
    protected loaderService: LoaderService,
    protected router: Router,
    protected modalService: ModalService
  ) {
    super(messagingService, authService, loaderService, router, modalService);
    this.formData = new AdminFormData();
  }

  adminLogin() {
    this.loaderService.showLoader();
    this.login();
  }
}
