<div *ngIf="tooltipData.reservation || tooltipData.flags?.length || tooltipData.private_user"
     class="outer-tooltip floor-{{ floorNumber }} tooltip-admin"
     [style.top]="tooltipData.top"
     [style.left]="tooltipData.left"
     [style.display]="tooltipData.display">

    <div *ngIf="tooltipData.reservation" class="top-div">
        <div *ngIf="tooltipData.reservation.user" class="overflow-ellipsis">
            <img class="user-icon" [src]="CONSTS.ICON_URL.USER_ICON_TOOLTIP" alt="user"/> <br/>

            {{ tooltipData.reservation.user.card_number || "-" }} <br/>
            {{ tooltipData.reservation.user.name || "-" }} <br/>
            {{ tooltipData.reservation.user.phone || "-" }} <br/>

            <div *ngFor="let plateNumber of tooltipData.reservation.user.licence_plate_numbers"
                 class="plate-number">

                {{ plateNumber.licence_plate_number }}

                <img class="star-icon"
                     *ngIf="plateNumber.primary"
                     [src]="CONSTS.ICON_URL.STAR_BLUE"
                     alt="star"/>
            </div>
        </div>

        <div *ngIf="!tooltipData.reservation.user && tooltipData.reservation.multi"
             class="overflow-ellipsis">
            <div class="reserved-for-title">Group reservation:</div>
            {{ tooltipData.reservation.name }}
        </div>

        <div *ngIf="!tooltipData.reservation.user && tooltipData.reservation.pre_reserved"
             class="overflow-ellipsis">
            <div class="reserved-for-title">Booked for:</div>
            {{ tooltipData.reservation.name }} <br/>
            {{ tooltipData.reservation.pre_reservation.from_time }} <br/>
            {{ tooltipData.reservation.pre_reservation.to_time }}
        </div>
    </div>

    <div *ngIf="tooltipData.private_user"
         class="top-div d-flex flex-column align-items-start overflow-ellipsis">

        <div class="reserved-for-title mb-1">Private user:</div>
        {{tooltipData.private_user.name}}<br/>
        {{tooltipData.private_user.phone}}<br/>
    </div>

    <div *ngIf="tooltipData.flags" class="top-div">
        <div class="d-flex tooltip-flags">
            <div *ngFor="let flag of tooltipData.flags">
                <img class="flag-icon mr-1" [title]="flag.name" [src]="flag.iconUrl" alt="">
            </div>
        </div>
    </div>

    <div class="bottom-div">
        <div class="hour">
            <app-count-down-timer
                [endDate]="tooltipData.reservation?.expiration_formatted">
            </app-count-down-timer>
        </div>
    </div>
    <div class="triangle"></div>
</div>

<div *ngIf="swapPlaceMode && swapTooltip.reservation"
     class="outer-tooltip floor-{{ floorNumber }} tooltip-admin tooltip-swap"
     [style.top]="swapTooltip.top"
     [style.left]="swapTooltip.left"
     [style.display]="swapTooltip.display"
>
    <div class="top-div">
        <div *ngIf="swapTooltip.reservation.user" class="overflow-ellipsis">
            Assign this spot to: <br/><br/>
            {{ swapTooltip.reservation.user.name }} <br/>
            {{ swapTooltip.reservation.user.phone }}
        </div>
    </div>
    <div class="bottom-div">
        <a class="confirm-link" (click)="emitOnSwap()">OK</a>
    </div>
    <div class="triangle"></div>
</div>
