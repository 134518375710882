import { Component } from '@angular/core';
import { DeviceService } from '../../../../services/device.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer-garage-user-device-chooser.component.html',
})
export class FooterGarageUserDeviceChooserComponent {
  isMobile: boolean;

  constructor(private deviceService: DeviceService) {
    this.isMobile = this.deviceService.isMobile();
  }
}
