import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from '../modules/auth/auth.service';
import { Router } from '@angular/router';
import { CONSTS } from '../constants';

@Injectable()
export class DeviceService {
  constructor(
    private deviceService: DeviceDetectorService,
    private authService: AuthService,
    private router: Router
  ) {}

  isRoleAdmin() {
    return this.authService.isAdmin;
  }

  isAdminLoginPage() {
    return this.router.url === CONSTS.PAGE_URL.ADMIN_LOGIN;
  }

  // disable mobile view in admin mode
  isMobile() {
    return this.isRoleAdmin() || this.isAdminLoginPage() ? false : this.deviceService.isMobile();
  }

  // disable mobile view in admin mode
  isDesktop() {
    return this.isRoleAdmin() || this.isAdminLoginPage() ? true : this.deviceService.isDesktop();
  }
}
