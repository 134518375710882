import { ParkingSlot } from './ParkingSlot';
import { User } from '../../../classes/User';

export class ReservationStatus {
  id: number;
  exist: boolean;
  user: User;
  final: boolean;
  expiration: string;
  expiration_formatted: string;
  expiration_extended: boolean;
  parking_slot: ParkingSlot;

  created_at: string;
  created_at_formatted: string;

  expired: boolean;
  changeParkingSlot: boolean;

  constructor(
    id: number = null,
    exist: boolean = null,
    user: User = null,
    final: boolean = null,
    expiration: string = null,
    expiration_formatted: string = null,
    expiration_extended: boolean = null,
    parking_slot: ParkingSlot = null,
    expired = false,
    changeParkingSlot = false,
    created_at = '',
    created_at_formatted = ''
  ) {
    this.id = id;
    this.exist = exist;
    this.user = user;
    this.final = final;
    this.expiration = expiration;
    this.expiration_formatted = expiration_formatted;
    this.expiration_extended = expiration_extended;
    this.parking_slot = parking_slot;
    this.expired = expired;
    this.changeParkingSlot = changeParkingSlot;
    this.created_at = created_at;
    this.created_at_formatted = created_at_formatted;
  }
}
