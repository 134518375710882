import { AfterViewInit, Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { D3GanttConfig } from './classes/d3GanttConfig';
import { CONSTS } from '../../../../../constants';
import * as moment from 'moment';
import * as d3 from 'd3';
import * as _ from 'underscore';
import { D3GanttChartDataListItem } from './classes/d3GanttChartDataListItem';
import { d3Selection } from './classes/d3Selection';

@Component({
  selector: 'app-d3-gantt-chart',
  templateUrl: './d3-gantt-chart.component.html',
  styleUrls: ['./d3-gantt-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class D3GanttChartComponent implements AfterViewInit {
  @ViewChild('ganttChart', { static: true }) ganttChart: ElementRef;

  @ViewChild('xAxisDiv', { static: true }) xAxisDiv: ElementRef;
  @ViewChild('xAxisDivCover', { static: true }) xAxisDivCover: ElementRef;
  @ViewChild('xAxisFloorDiv', { static: true }) xAxisFloorDiv: ElementRef;
  @ViewChild('xAxisFloorTextDiv', { static: true }) xAxisFloorTextDiv: ElementRef;

  @ViewChild('yAxisDiv', { static: true }) yAxisDiv: ElementRef;

  @ViewChild('floor2Div', { static: true }) floor2Div: ElementRef;
  @ViewChild('floor2TextDiv', { static: true }) floor2TextDiv: ElementRef;

  @ViewChild('currentTimeLineDiv', { static: true }) currentTimeLineDiv: ElementRef;

  @Input() day: string;
  @Input() dataList: D3GanttChartDataListItem[];
  @Input() dataNames: number[];
  @Input() floorNumber: number;
  @Input() hideXaxisText: boolean;

  height: number;
  width: number;

  lineHeight: number = 36;
  rectHeight: number = 36;

  tickWidth: number = 90;
  tickCount: number = 24;
  tickFormat: string = '%H:%M';

  yAxisTextLeftPadding = 15;

  xScale: d3.ScaleTime<number, number>;
  yScale: d3.ScaleBand<any>;
  xAxis: d3.Axis<any>;
  xAxisMain: d3.Axis<any>;
  yAxis: d3.Axis<any>;
  yAxisMain: d3.Axis<any>;

  margin = {
    top: 0,
    right: 15,
    bottom: this.lineHeight,
    left: this.tickWidth,
  };

  config: D3GanttConfig = {
    floorNumber: 0,
    hideXaxisText: false,
    field: {
      startDate: null,
      endDate: null,
      yAxisValue: null,
    },
  };

  animDuration = 500;

  ngAfterViewInit() {
    let config = new D3GanttConfig();
    config.floorNumber = this.floorNumber;
    config.hideXaxisText = this.hideXaxisText;
    config.field = {
      startDate: 'from_time',
      endDate: 'to_time',
      yAxisValue: 'slot_number',
    };

    this.drawGantt(this.ganttChart.nativeElement, this.dataList, config);
    this.addScrollListener();
  }

  drawGantt(element: HTMLElement, tasks: D3GanttChartDataListItem[], config: D3GanttConfig) {
    this.width = this.tickCount * this.tickWidth - this.margin.right - this.margin.left - 5;
    this.height = this.dataNames.length * this.lineHeight;

    let fullHeight = this.height + this.margin.top + this.margin.bottom;
    let fullWidth = this.width + this.margin.left + this.margin.right;

    this.initConfig(config);
    this.initXaxis();
    this.initYaxis();

    // add main SVG
    let svg = d3
      .select(element)
      .append('svg')
      .attr('class', 'chart')
      .attr('width', fullWidth)
      .attr('height', fullHeight);

    // add domain
    let svgG = svg
      .append('g')
      .attr('class', 'gantt-chart')
      .attr('width', fullWidth)
      .attr('height', fullHeight)
      .attr('transform', 'translate(' + this.margin.left + ', ' + this.margin.top + ')');

    // add header row to bottom of x-axis
    this.addHeaderRow(this.width + this.margin.left);
    this.addLeftColumn();

    this.addTasks(svgG, tasks);

    this.addChartXaxis(svg);
    this.addChartYaxis(svgG);

    this.addFloor2HeaderRow(svgG, this.width + this.margin.left);
    this.addCurrentTimeLine(fullHeight);

    // remove domain
    svgG.selectAll('.domain').remove();
  }

  addScrollListener() {
    let xAxisd3 = d3.select(this.xAxisDiv.nativeElement);
    let yAxisd3 = d3.select(this.yAxisDiv.nativeElement);

    let floor2DivD3 = d3.select(this.floor2Div.nativeElement);
    let floor2TextDivD3 = d3.select(this.floor2TextDiv.nativeElement);

    let xAxisFloorDivD3 = d3.select(this.xAxisFloorDiv.nativeElement);
    let xAxisFloorTextDivD3 = d3.select(this.xAxisFloorTextDiv.nativeElement);

    let timeLineDivD3 = d3.select(this.currentTimeLineDiv.nativeElement);

    const dragScrollElement = document.getElementsByClassName('drag-scroll-content')[0];

    dragScrollElement.addEventListener(
      'scroll',
      () => {
        let scrollTop = dragScrollElement.scrollTop;
        let scrollLeft = dragScrollElement.scrollLeft;

        // set header axis div position
        xAxisd3.style('left', '-' + scrollLeft + 'px');
        yAxisd3.style('top', 'calc(' + this.lineHeight * 2 + 'px - ' + scrollTop + 'px)');

        // set floor-2 row div position
        let floor2DivTop = Math.max(this.lineHeight, parseFloat(floor2DivD3.attr('original-top')) - scrollTop);

        floor2DivD3.style('left', '-' + scrollLeft + 'px');
        floor2DivD3.style('top', floor2DivTop + 'px');
        floor2TextDivD3.style('top', floor2DivTop + this.lineHeight * 0.3 + 'px');

        // set header floor div position
        let headerFloorTop = Math.min(this.lineHeight, floor2DivTop - this.lineHeight);

        xAxisFloorDivD3.style('left', '-' + scrollLeft + 'px');
        xAxisFloorDivD3.style('top', headerFloorTop + 'px');
        xAxisFloorTextDivD3.style('top', headerFloorTop + this.lineHeight * 0.3 + 'px');

        // set timeLine position
        let timeLineLeft = parseFloat(timeLineDivD3.attr('original-left')) - scrollLeft;
        timeLineDivD3.style('left', timeLineLeft + 'px');

        // hide timeLine if left if lower than y-axis width
        timeLineDivD3.style('display', timeLineLeft < this.tickWidth ? 'none' : 'inline-block');
      },
      false
    );
  }

  initConfig(_config: D3GanttConfig) {
    this.config = _.extend(this.config, _config);

    if (this.config.hideXaxisText) {
      this.margin.top = this.lineHeight;
    }
  }

  initXaxis() {
    let startDate = new Date(this.day + ' 00:00:00');
    let endDate = new Date(this.day + ' 24:00:00');

    this.xScale = d3.scaleTime().domain([startDate, endDate]).range([0, this.width]).clamp(true);
  }

  initYaxis() {
    this.yScale = d3
      .scaleBand()
      .padding(0)
      .domain(this.dataNames.map((item) => item.toString()))
      .range([0, this.height]);

    this.yAxisMain = d3
      .axisLeft(this.yScale)
      .tickSize(this.tickWidth)
      .tickPadding(-this.tickWidth / 4);
  }

  addHeaderRow(headerWidth: number) {
    let xAxisPlusWidth = 30;

    this.xAxisMain = d3
      .axisTop(this.xScale)
      .tickFormat(d3.timeFormat(this.tickFormat) as any)
      .tickSize(0)
      .tickPadding(this.lineHeight / 3)
      .ticks(this.tickCount);

    // add x axis SVG
    let xAxisSVG = d3
      .select(this.xAxisDiv.nativeElement)
      .style('width', headerWidth + xAxisPlusWidth + 'px')
      .style('height', this.lineHeight + 'px')
      .append('svg')
      .attr('width', headerWidth + xAxisPlusWidth)
      .attr('height', this.lineHeight);

    // add global x-Axis
    let xSVGAxisG = xAxisSVG
      .append('g')
      .attr('class', 'x-axis-svg-g')
      .attr('transform', 'translate(' + this.margin.left + ', ' + this.lineHeight + ')');
    xSVGAxisG.call(this.xAxisMain);

    // format global x-Axis
    xSVGAxisG.selectAll('.tick text').attr('fill', 'white').style('font-family', 'EYInterstateBold');

    // set x-axis floor header ROW div style
    d3.select(this.xAxisFloorDiv.nativeElement)
      .style('width', headerWidth + 'px')
      .style('height', this.lineHeight + 'px')
      .style('top', this.lineHeight + 'px');

    // set x-axis floor header TEXT div style
    d3.select(this.xAxisFloorTextDiv.nativeElement)
      .style('top', this.lineHeight * 1.3 + 'px')
      .style('left', this.yAxisTextLeftPadding + 'px');

    // set x-axis floor header COVER div style
    d3.select(this.xAxisDivCover.nativeElement)
      .style('width', this.tickWidth - 15 + 'px')
      .style('height', this.lineHeight + 'px');
  }

  addLeftColumn() {
    // add y axis SVG
    let yAxisSVG = d3
      .select(this.yAxisDiv.nativeElement)
      .style('height', this.height + 'px')
      .style('width', this.tickWidth + 'px')
      .style('top', this.lineHeight * 2 + 'px')
      .append('svg')
      .attr('class', 'y-axis-svg')
      .attr('width', this.tickWidth)
      .attr('height', this.height);

    // add global y-Axis
    let ySVGAxisG = yAxisSVG
      .append('g')
      .attr('class', 'y-axis-svg-g')
      .attr('transform', 'translate(' + this.tickWidth + ', ' + this.lineHeight / 2 + ')');
    ySVGAxisG.call(this.yAxisMain);

    this.formatLeftColumnAxis(ySVGAxisG);
  }

  formatLeftColumnAxis(ySVGAxisG: d3Selection<any>) {
    // format global y-axis
    ySVGAxisG
      .selectAll('.tick text')
      .attr('fill', 'white')
      .attr('text-anchor', 'start')
      .attr('dy', -12)
      .style('font-family', 'EYInterstateBold');

    // format tick y-axis
    ySVGAxisG.selectAll('.tick line').attr('stroke', '#202020');
    // remove domain
    ySVGAxisG.selectAll('.domain').remove();
  }

  addChartXaxis(svg: d3Selection<any>) {
    this.xAxis = d3
      .axisTop(this.xScale)
      .tickFormat(null)
      .tickSize(this.height)
      .tickPadding(0)
      .ticks(this.tickCount);

    // add tick x-Axis
    let xTickAxis = svg
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', 'translate(' + this.margin.left + ', ' + this.height + ')');
    xTickAxis.call(this.xAxis);

    this.formatChartXaxis(xTickAxis);
  }

  formatChartXaxis(xTickAxis: d3Selection<any>) {
    // format tick x-axis
    xTickAxis.selectAll('.tick line').attr('stroke', '#707070');
  }

  addChartYaxis(svgG: d3Selection<any>) {
    this.yAxis = d3
      .axisLeft(undefined)
      .scale(this.yScale)
      .tickFormat(null)
      .tickSize(this.width + this.tickWidth)
      .tickPadding(0);

    // add tick y-Axis
    let ySVGAxis = svgG
      .append('g')
      .attr('class', 'y-axis')
      .attr('transform', 'translate(' + this.width + ', ' + this.lineHeight / 2 + ')');
    ySVGAxis.call(this.yAxis);

    this.formatChartYaxis(ySVGAxis);
  }

  formatChartYaxis(ySVGAxis: d3Selection<any>) {
    // format tick y-Axis
    ySVGAxis.selectAll('.tick line').attr('stroke', '#202020');
  }

  removeTasks(svgG: d3Selection<any>) {
    svgG.selectAll('.bar-container').remove();
  }

  addTasks(svgG: d3Selection<any>, tasks: D3GanttChartDataListItem[]) {
    // add g element
    let rectG = svgG.selectAll('.bar-container').data(tasks).enter().append('g').attr('class', 'bar-container');

    // add title element
    rectG.append('title').text((data) => {
      return data.data.name + ' (' + data.data.from_time + ' - ' + data.data.to_time + ')';
    });

    // add rect element
    rectG
      .append('rect')
      .attr('class', (data: D3GanttChartDataListItem) => {
        let curClass = 'bar ';

        if (data.data.reservation && data.data.reservation.pre_reserved) {
          curClass += 'active';
        } else {
          curClass += 'in-active';
        }

        return curClass;
      })
      .attr('y', (data: D3GanttChartDataListItem) => {
        return this.yScale(data[this.config.field.yAxisValue]);
      })
      .attr('x', (data) => {
        return this.xScale(data[this.config.field.startDate]);
      })
      .attr('height', this.rectHeight)
      .transition()
      .duration(500)
      .attr('width', (data) => {
        return this.xScale(data[this.config.field.endDate]) - this.xScale(data[this.config.field.startDate]);
      });
  }

  addFloor2HeaderRow(svgG: d3Selection<any>, fullWidth: number) {
    let top = this.yScale('2000') + this.lineHeight * 2;

    // style floor 2 ROW div
    d3.select(this.floor2Div.nativeElement)
      .style('width', fullWidth + 'px')
      .style('height', this.rectHeight + 'px')
      .style('top', top + 'px')
      .attr('original-top', top);

    // style floor 2 TEXT div
    d3.select(this.floor2TextDiv.nativeElement)
      .style('left', this.yAxisTextLeftPadding + 'px')
      .style('top', top + this.lineHeight * 0.3 + 'px');
  }

  addCurrentTimeLine(fullHeight: number) {
    let timeLined3 = d3.select(this.currentTimeLineDiv.nativeElement);

    if (moment().format(CONSTS.DATE_FORMAT) === this.day) {
      let left = this.xScale(new Date()) + this.margin.left;

      // style current-time DIV
      timeLined3
        .style('top', this.lineHeight + this.margin.top + 'px')
        .style('left', left + 'px')
        .attr('original-left', left)
        .attr('original-left', left)
        .style('height', fullHeight + 'px')
        .transition()
        .delay(this.animDuration + 1)
        .duration(this.animDuration)
        .style('opacity', '1');
    } else {
      timeLined3.style('opacity', '0').style('height', '0').style('left', '0');
    }
  }

  reDrawChart(tasks: D3GanttChartDataListItem[], slotNumbers: number[], day: string) {
    this.dataList = tasks;
    this.dataNames = slotNumbers;
    this.day = day;

    this.height = this.dataNames.length * this.lineHeight;
    let fullHeight = this.height + this.margin.top + this.margin.bottom;

    // update SVG height
    let svgG = d3.select('.gantt-chart');
    d3.select('.chart').transition().duration(this.animDuration).attr('height', fullHeight);
    svgG.transition().duration(this.animDuration).attr('height', fullHeight);

    this.initXaxis();
    // update init y axis
    this.initYaxis();

    // update left fix y-axis
    d3.select(this.yAxisDiv.nativeElement)
      .transition()
      .duration(this.animDuration)
      .style('height', this.height + 'px');
    d3.select('.y-axis-svg')
      .transition()
      .duration(this.animDuration)
      .style('height', this.height + 'px');
    let ySVGAxisG: d3Selection<SVGGElement> = d3.select('.y-axis-svg-g');
    ySVGAxisG.transition().duration(this.animDuration).call(this.yAxisMain);
    this.formatLeftColumnAxis(ySVGAxisG);

    // update tasks
    this.removeTasks(svgG);

    // update chart x-axis
    this.xAxis = this.xAxis.tickSize(this.height);
    let chartXaxis: d3Selection<SVGGElement> = d3.select('.x-axis');
    chartXaxis.transition().duration(this.animDuration).call(this.xAxis);
    chartXaxis
      .transition()
      .duration(this.animDuration)
      .attr('transform', 'translate(' + this.margin.left + ', ' + this.height + ')');
    this.formatChartXaxis(chartXaxis);

    // update chart y-axis
    this.yAxis = this.yAxis.scale(this.yScale);
    let chartYaxis: d3Selection<SVGGElement> = d3.select('.y-axis');
    chartYaxis.transition().duration(this.animDuration).call(this.yAxis);
    this.formatChartYaxis(chartYaxis);

    // update floor 2 header row
    let top = this.yScale('2000') + this.lineHeight * 2;
    d3.select(this.floor2Div.nativeElement)
      .transition()
      .duration(this.animDuration)
      .style('top', top + 'px')
      .attr('original-top', top);

    d3.select(this.floor2TextDiv.nativeElement)
      .transition()
      .duration(this.animDuration)
      .style('top', top + this.lineHeight * 0.3 + 'px');

    // update timeLine
    this.addCurrentTimeLine(fullHeight);

    setTimeout(() => {
      this.addTasks(svgG, tasks);
    }, this.animDuration);

    // remove domain
    svgG.selectAll('.domain').remove();
  }
}
