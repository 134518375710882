import { AfterViewInit, Component } from '@angular/core';
import { MapChartGarageUserComponent } from '../map-chart/map-chart-garage-user/map-chart-garage-user.component';

@Component({
  selector: 'app-map-chart-floor-2',
  templateUrl: './map-chart-floor-2.component.html',
  styleUrls: ['../map-chart/map-chart-common/map-chart-common.component.scss'],
})
export class MapChartFloor2Component extends MapChartGarageUserComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    this.init();
  }
}
