import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';

export const LogTableCommonColumns: ColDef[] = [
  {
    headerName: 'ID',
    field: 'id',
    resizable: true,
    sortable: true,
    width: 70,
    suppressSizeToFit: true,
  },
  {
    headerName: 'User ID',
    field: 'user_id',
    resizable: true,
    sortable: true,
    width: 80,
    suppressSizeToFit: true,
  },
  {
    headerName: 'User Name',
    field: 'user_name',
    resizable: true,
    sortable: true,
    width: 120,
    suppressSizeToFit: true,
  },
  {
    headerName: 'Type',
    field: 'type',
    cellClass: 'col-grey-color',
    resizable: true,
    sortable: true,
    width: 150,
    suppressSizeToFit: true,
  },
  {
    headerName: 'Event',
    field: 'event',
    resizable: true,
    sortable: true,
    suppressSizeToFit: true,
    width: 200,
    cellClass: 'col-grey-color',
  },
];
