import { Injectable } from '@angular/core';
import { AuthService } from '../modules/auth/auth.service';
import { LoaderService } from './loader.service';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { ModalService } from './modal.service';
import { Helper } from '../helper';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root',
})
export class FirebaseMessagingService {
  serviceWorkerUrl: string = '/firebase-messaging-sw.js';

  private SERVICE_WORKER_CHECK_COUNT = 5;
  private SERVICE_WORKER_CHECK_DELAY = 1000;

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private authService: AuthService,
    private loaderService: LoaderService,
    private modalService: ModalService
  ) {}

  requestPermission(count: number = 0) {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.register(this.serviceWorkerUrl).then((registration) => {
        setTimeout(() => {
          this.getToken(registration);
        });
      });
    } else if (count < this.SERVICE_WORKER_CHECK_COUNT) {
      const nextCount = ++count;
      console.warn('service worker not found. Try to reach it again: ', nextCount);

      setTimeout(() => {
        this.requestPermission(nextCount);
      }, this.SERVICE_WORKER_CHECK_DELAY);
    } else {
      alert('service worker not found! Push notifications will not work. Please try to login again.');
    }
  }

  private getToken(registration: ServiceWorkerRegistration) {
    const messaging = firebase.messaging();
    messaging
      .getToken({
        serviceWorkerRegistration: registration,
      })
      .then((token) => {
        this.sendDeviceToken(token);
      });
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe({
      next: (payload: firebase.messaging.MessagePayload) => {
        const payloadNotification = payload.notification;

        Notification.bind(new Notification(payloadNotification.title, { body: payloadNotification.body }));
      },
    });
  }

  sendDeviceToken(token: string) {
    this.loaderService.showLoader();

    this.authService.sendFirebaseMessagingDeviceToken(token).subscribe({
      next: () => {
        this.loaderService.hideLoader();
      },
      error: (error) => {
        const errorDetails = Helper.getErrorDetails(error);
        this.modalService.openAlertModal(errorDetails['message'], errorDetails['detail']);
        this.loaderService.hideLoader();
      },
    });
  }
}
