import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PreReservation } from '../../../../classes/PreReservation';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ParkPlaceSelectAdminService } from '../../../../services/park-place-select-admin.service';
import { ParkingSlot } from '../../../../classes/ParkingSlot';
import * as moment from 'moment';
import { CONSTS } from '../../../../../../constants';
import { DateTimePickerComponent } from '../../../../../shared/components/date-time-picker/date-time-picker.component';
import { AdminPreReservationModalData } from './classes/AdminPreReservationModalData';

@Component({
  selector: 'app-admin-pre-reservation-edit-modal',
  templateUrl: './admin-pre-reservation-edit-modal.component.html',
  styleUrls: ['./admin-pre-reservation-edit-modal.component.scss'],
})
export class AdminPreReservationEditModalComponent implements OnInit {
  @Input() data: AdminPreReservationModalData;

  @ViewChild('dateTimePickerFrom', { static: true }) dateTimePickerFrom: DateTimePickerComponent;
  @ViewChild('dateTimePickerTo', { static: true }) dateTimePickerTo: DateTimePickerComponent;

  parkingSlot: ParkingSlot;
  preReservation: PreReservation;

  initDate: string;

  mode: string;
  minDate: Date;

  constructor(
    public activeModal: NgbActiveModal,
    private parkPlaceSelectAdminService: ParkPlaceSelectAdminService
  ) {}

  ngOnInit() {
    this.parkingSlot = this.data['parkingSlot'];

    if (!this.data['preReservation']) {
      this.mode = 'create';
      this.preReservation = new PreReservation();
      this.preReservation.parking_slot_id = this.parkingSlot.id;

      if (this.data['initDate']) {
        this.initDate = moment(this.data['initDate']).format(CONSTS.DATE_FORMAT);
      } else {
        this.initDate = null;
      }
    } else {
      this.mode = 'edit';
      this.preReservation = Object.assign({}, this.data['preReservation']);
    }

    // set min selectable date for date-picker
    let curDate = moment(new Date());
    if (this.parkingSlot.reservation && !this.parkingSlot.reservation.pre_reserved) {
      curDate.add(1, 'days');
    }
    this.minDate = curDate.toDate();
  }

  save() {
    if (this.mode === 'create') {
      this.parkPlaceSelectAdminService
        .addPreReservation(this.preReservation)
        .subscribe((preReservation: PreReservation) => {
          this.activeModal.close(preReservation);
        });
    } else {
      this.parkPlaceSelectAdminService
        .updatePreReservation(this.preReservation)
        .subscribe((preReservation: PreReservation) => {
          this.activeModal.close(preReservation);
        });
    }
  }

  validInterval() {
    return new Date(this.preReservation.from_time) < new Date(this.preReservation.to_time);
  }

  updateFromDate(fromDate: string) {
    let toDate = moment(this.dateTimePickerFrom.getDate()).format(CONSTS.DATE_FORMAT);

    if (fromDate > toDate) {
      this.dateTimePickerTo.updateDate(new Date(fromDate));
    }
  }

  updateToDate(toDate: string) {
    let fromDate = moment(this.dateTimePickerTo.getDate()).format(CONSTS.DATE_FORMAT);

    if (toDate < fromDate) {
      this.dateTimePickerFrom.updateDate(new Date(toDate));
    }
  }

  isEmpty(str: string) {
    return !str || 0 === str.length;
  }
}
