<div class="floor-select-content" [ngClass]="{ mobile: isMobile }">
    <div class="logout hover-bigger">
        <a (click)="logout()" title="logout">
            <img alt="logout" src="/assets/images/icon_logout.svg"/>
        </a>
    </div>

    <div appAutoScale="{{ autoScale }}" class="floor-selector-container">
        <app-floor-selector
                [showLoader]="!freePlaceCount"
                [floorNumber]="1"
                [freePlaceCount]="freePlaceCount?.free_places.floor_1">
        </app-floor-selector>
        <app-floor-selector
                [showLoader]="!freePlaceCount"
                [floorNumber]="2"
                [freePlaceCount]="freePlaceCount?.free_places.floor_2">
        </app-floor-selector>
    </div>
</div>
