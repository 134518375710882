<div class="add-icon">
    <div (click)="addSpecialFlag()" title="add new special flag" class="add-icon-div">
        <img [src]="CONSTS.ICON_URL.ADD_ICON" alt="add"/>
        <div class="add-text">ADD FLAG</div>
    </div>

    <input class="filter-list"
           type="text"
           title="search in special flag list"
           placeholder="Search"
           (ngModelChange)="setSpecialFlagListTableFilter($event)"
           [ngModel]="filterValue"
           name="filterFlagList"/>
</div>

<div *ngIf="showLoader" class="loader-container">
    <app-loader [showLoader]="true"></app-loader>
</div>

<div *ngIf="!showLoader">
    <ag-grid-angular
        class="ag-theme-bootstrap special-flags-table"
        [gridOptions]="gridOptions">
    </ag-grid-angular>
</div>

