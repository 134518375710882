import { first, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CONSTS } from '../../../constants';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ParkingSlot } from '../classes/ParkingSlot';
import { ReservationStatus } from '../classes/ReservationStatus';
import { Reservation } from '../classes/Reservation';
import * as _ from 'underscore';
import { HttpService } from '../../../services/http.service';
import { LoaderService } from '../../../services/loader.service';
import { User } from '../../../classes/User';
import * as moment from 'moment';
import { AuthService } from '../../auth/auth.service';
import { ParkingSlotResponse } from '../classes/ParkingSlotResponse';

@Injectable()
export class ParkPlaceSelectGarageUserService {
  private reservationStatus = new BehaviorSubject<ReservationStatus>(undefined);
  private previousReservationStatus = new ReservationStatus();
  private refreshParkingSlots = new Subject<Array<ParkingSlot>>();
  private userWithBikeStatus = new BehaviorSubject<boolean>(false);

  private selectFavouriteParkingPlace = new BehaviorSubject<boolean>(false);

  constructor(
    protected httpService: HttpService,
    protected authService: AuthService,
    protected loaderService: LoaderService
  ) {}

  setReservationStatus(status: ReservationStatus, force = false) {
    // only update if status is changed!
    if (force || !_.isEqual(this.previousReservationStatus, status)) {
      console.log('reservationStatus changed: ', status);

      this.reservationStatus.next(status);
    }
    this.previousReservationStatus = status;
  }

  getReservationStatus(): Observable<ReservationStatus> {
    return this.reservationStatus.asObservable();
  }

  getSelectFavouriteParkingPlaceStatus(): Observable<boolean> {
    return this.selectFavouriteParkingPlace.asObservable();
  }

  setSelectFavouriteParkingPlaceStatus(status: boolean) {
    return this.selectFavouriteParkingPlace.next(status);
  }

  getUserWithBikeStatus(): Observable<boolean> {
    return this.userWithBikeStatus.asObservable();
  }

  setUserWithBikeStatus(withBike: boolean) {
    return this.userWithBikeStatus.next(withBike);
  }

  // admin chart listens for this and will hide loader after parking place refresh has finished!
  forceParkingSlotsRefresh(parkingSlots: Array<ParkingSlot>) {
    this.refreshParkingSlots.next(parkingSlots);
  }

  getRefreshParkingSlotsSub() {
    return this.refreshParkingSlots.asObservable();
  }

  getParkingSlots(floor: number, ignoreError: boolean): Observable<ParkingSlotResponse> {
    return this.httpService.get(CONSTS.API_END_POINT.PARKING_SLOT + '/current/' + floor, null, ignoreError).pipe(
      map((result) => {
        return {
          parkingSlots: <Array<ParkingSlot>>result.parking_slots,
          freePlaceCount: result.free_user_place_count,
        };
      })
    );
  }

  reserveParkingSlot(selectedParkingSlot: string | number): Observable<Reservation> {
    this.loaderService.showLoader();

    let data = {
      slot_number: selectedParkingSlot.toString(),
    };
    return this.httpService.post(CONSTS.API_END_POINT.RESERVATION + '/current', data).pipe(
      map((result) => {
        return <Reservation>result.reservation;
      }),
      tap((result) => {
        this.updateReservationStatus(result);
      })
    );
  }

  changeParkingSlot(selectedParkingSlot: number): Observable<Reservation> {
    this.loaderService.showLoader();

    let data = {
      slot_number: selectedParkingSlot.toString(),
    };

    return this.httpService.put(CONSTS.API_END_POINT.RESERVATION + '/current', data).pipe(
      tap((result) => {
        this.updateReservationStatus(result.reservation);
        this.forceParkingSlotsRefresh([result.parking_slot]);
      }),
      map((result) => {
        return <Reservation>result.reservation;
      })
    );
  }

  getCurrentReservation(forceUpdate = false, ignoreError = false): Observable<Reservation> {
    return this.httpService.get(CONSTS.API_END_POINT.RESERVATION + '/current', null, ignoreError).pipe(
      tap((result) => {
        this.updateReservationStatus(result.reservation, result.user, forceUpdate);
      }),
      map((result) => {
        return <Reservation>result.reservation;
      })
    );
  }

  extendReservation(): Observable<Reservation> {
    this.loaderService.showLoader();

    return this.httpService.get(CONSTS.API_END_POINT.RESERVATION + '/current/extend').pipe(
      map((result) => {
        return <Reservation>result.reservation;
      }),
      tap((result) => {
        this.updateReservationStatus(result);
      })
    );
  }

  finalizeReservation(): Observable<Reservation> {
    this.loaderService.showLoader();

    return this.httpService.get(CONSTS.API_END_POINT.RESERVATION + '/current/finalize').pipe(
      map((result) => {
        return <Reservation>result.reservation;
      }),
      tap((result) => {
        this.updateReservationStatus(result);
      })
    );
  }

  cancelReservation() {
    this.loaderService.showLoader();

    return this.httpService.delete(CONSTS.API_END_POINT.RESERVATION + '/current').pipe(
      tap(() => {
        this.getCurrentReservation(true).pipe(first()).subscribe();
      })
    );
  }

  updateReservationStatus(reservation: Reservation, user: User = null, force = false) {
    let reservationStatus;

    if (reservation) {
      let expired = false;
      if (reservation.expiration) {
        let currentTime = new Date().getTime();
        let endTime = new Date(moment(reservation.expiration).format('YYYY-MM-DDTHH:mm:ss')).getTime();

        expired = currentTime >= endTime;
      }

      reservationStatus = new ReservationStatus(
        reservation.id,
        true,
        reservation.user,
        reservation.is_final,
        reservation.expiration,
        reservation.expiration_formatted,
        reservation.expiration_extended,
        reservation.parking_slot,
        expired,
        this.previousReservationStatus.changeParkingSlot,
        reservation.created_at,
        reservation.created_at_formatted
      );
    } else {
      reservationStatus = new ReservationStatus();
    }

    if (user) {
      reservationStatus.user = user;

      this.authService.updateUser(user);
      this.setUserWithBikeStatus(user.bike);
    }

    this.setReservationStatus(reservationStatus, force);

    this.loaderService.hideLoader();
  }

  theyTookMyPlace(licence_plate_number: string) {
    this.loaderService.showLoader();

    let data = {
      licence_plate_number: licence_plate_number,
    };

    return this.httpService.post(CONSTS.API_END_POINT.RESERVATION + '/current/place-taken', data).pipe(
      tap((result) => {
        if (result.reservation) {
          this.updateReservationStatus(result.reservation);
          this.forceParkingSlotsRefresh([result.parking_slot]);
        }
      })
    );
  }

  removeUserPopup() {
    let data: { [key: string]: null } = {
      popup: null,
    };

    return this.httpService.put(CONSTS.API_END_POINT.USER + '/current', data);
  }

  iAmWithBike(withBike: boolean) {
    this.loaderService.showLoader();
    let data = {
      bike: withBike,
    };

    this.httpService
      .put(CONSTS.API_END_POINT.USER + '/current', data)
      .pipe(
        tap(() => {
          this.getCurrentReservation(true)
            .pipe(first())
            .subscribe(() => {
              this.userWithBikeStatus.next(withBike);
              this.loaderService.hideLoader();
            });
        }),
        first()
      )
      .subscribe();
  }

  getReleaseDates(token: string): Observable<{ date: string }[]> {
    return this.httpService.get(CONSTS.API_END_POINT.USER_RELEASE_DATES, null, false, { token });
  }

  setReleaseDates(token: string, dates: string[]): Observable<unknown> {
    return this.httpService.post(CONSTS.API_END_POINT.USER_RELEASE_DATES, { dates }, null, { token });
  }
}
