import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { CommonComponent } from '../../common/common.component';
import { AdminMenuService } from '../../../services/admin-menu.service';
import * as moment from 'moment';
import { CONSTS } from '../../../../../constants';
import { LoaderComponent } from '../../../../shared/components/loader/loader.component';
import { Observable } from 'rxjs';
import { DateTimeRangeEvent } from '../../../../shared/components/date-time-picker/classes/DateTimePickerEvent';
import { TookMyPlaceRow } from '../../../classes/TookMyPlaces';
import { Overnights } from '../../../classes/Overnights';

@Component({
  selector: 'app-common-report',
  template: '<ng-content></ng-content>',
})
export abstract class CommonReportComponent extends CommonComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedDateRange: DateTimeRangeEvent;
  @Input() onlyValid: boolean = false;

  gridOptions: GridOptions;
  showLoader: boolean = true;

  fromDate: string;
  toDate: string;

  queryEndpoint: Observable<TookMyPlaceRow[] | Overnights[]>;

  readonly DEFAULT_ROW_HEIGHT = 50;
  readonly INNER_TABLE_MARGIN = 10;
  readonly INNER_TABLE_ROW_HEIGHT = 43;

  protected constructor(protected adminMenuService: AdminMenuService) {
    super();
  }

  ngOnInit() {
    this.initCommonGridOptions();
  }

  ngOnChanges() {
    this.fromDate = moment(this.selectedDateRange.date).format(CONSTS.DATE_FORMAT);
    this.toDate = moment(this.selectedDateRange.toDate).format(CONSTS.DATE_FORMAT);

    this.setQueryEndpoint();

    this.getReportList();
  }

  initCommonGridOptions() {
    let that = this;

    this.gridOptions = {
      loadingOverlayComponent: LoaderComponent,
      overlayNoRowsTemplate: '<span class="overlay-no-rows">Nothing to display</span>',
      cacheQuickFilter: true,
      rowHeight: 50,
      headerHeight: 50,
      rowData: [],
      onGridReady: () => {
        if (that.gridOptions.api) {
          that.gridOptions.api.sizeColumnsToFit();
        }
      },
      onGridSizeChanged: () => {
        if (that.gridOptions.api) {
          that.gridOptions.api.sizeColumnsToFit();
        }
      },
    };
  }

  protected abstract setQueryEndpoint(): void;

  getReportList() {
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.showLoadingOverlay();
    }

    setTimeout(() => {
      this.clearGetListSub();

      const func = this.queryEndpoint as Observable<any[]>;
      this.getListSub = func.subscribe((result: Array<any>) => {
        if (this.gridOptions.api) {
          this.gridOptions.api.setRowData(result);
        } else {
          this.gridOptions.rowData = result;
        }

        this.showLoader = false;
      });
    });
  }

  protected stopPropagation(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }
}
