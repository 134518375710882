<label class="switch" title="toggle user status">
    <input type="checkbox" [(ngModel)]="user.status"/>
    <span (click)="setUserStatus()"
          class="slider round"
          [class.user-active]="user.status">
    </span>
</label>
<div class="switch-label switch-label-active">
    {{ user.status ? "active" : "Inactive" }}
</div>
