import { GridOptions, ICellRendererParams } from 'ag-grid-community';
import { Exception } from '../classes/Log';
import { LogTableCommonColumns } from '../classes/LogTableCommonColumns';
import { LogTableComponent } from '../components/log-table/log-table.component';
import { CONSTS } from '../../../../../constants';
import { LogTableConfigUtil } from '../../../classes/LogTableConfigUtil';
import * as moment from 'moment';

export class ExceptionLogTableConfig {
  static getGridOptions(instance: LogTableComponent): GridOptions {
    return {
      ...LogTableConfigUtil.getCommonOptions<Exception>(instance),
      onCellMouseOver: (params) => {
        $(params.event.target).find('.info-icon').tooltip('show');
      },
      onCellMouseOut: (params) => {
        $(params.event.target).find('.info-icon').tooltip('dispose');
      },
      columnDefs: LogTableCommonColumns.concat([
        {
          headerName: 'File',
          field: 'file',
          resizable: true,
          width: 200,
          sortable: true,
          cellClass: 'col-grey-color',
        },
        {
          headerName: 'Line',
          field: 'line',
          resizable: true,
          sortable: true,
          width: 120,
          cellClass: 'col-grey-color',
        },
        {
          headerName: 'Message',
          field: 'message',
          resizable: true,
          sortable: true,
          cellClass: 'col-grey-color',
        },
        {
          headerName: 'Stack Trace',
          field: 'trace',
          resizable: true,
          sortable: true,
          width: 100,
          cellClass: 'col-grey-color',
          cellRenderer: (params: ICellRendererParams) => {
            return (
              '<div class="info-cell"><img alt="info icon" class="' +
              (params.value ? 'info-icon' : 'info-icon disabled') +
              '" data-placement="left" title="' +
              (params.value ?? '') +
              '"  src="' +
              CONSTS.ICON_URL.THEY_TOOK_MY_PLACE +
              '"/></div>'
            );
          },
        },
        {
          headerName: 'Time',
          field: 'created_at',
          resizable: true,
          sortable: true,
          width: 200,
          suppressSizeToFit: true,
          cellRenderer: (params: ICellRendererParams) => {
            return moment(params.value).format('MM-DD HH:mm');
          },
        },
      ]),
    };
  }
}
