<app-modal-header [activeModal]="activeModal"></app-modal-header>
<div class="modal-body">
    <div class="park-place-modal-title">
        <div class="park-place-floor">Floor -{{ parkingSlot.floor }}</div>
        <div class="park-place-slot">{{ parkingSlot.slot_number }}</div>
    </div>

    <div class="form-container">
        <div class="title">BOOKING</div>
        <div class="date-error">
              <span *ngIf="preReservation.from_time && preReservation.to_time && !validInterval()">
                 date/time mismatch!<br/>please set date/times properly!
              </span>
        </div>
        <form #preReservationForm="ngForm">
            <div class="form-container">
                <div class="name-row">
                    <input [ngClass]="{
                              'has-error': isEmpty(preReservation.name) && (name.dirty || name.touched)
                           }"
                           class="form-control"
                           name="name"
                           id="name"
                           #name="ngModel"
                           required
                           placeholder="booking name"
                           [(ngModel)]="preReservation.name"
                    />
                </div>
                <div class="date-row">
                    <app-date-time-picker
                        #dateTimePickerFrom
                        [isError]="
                            preReservation.from_time &&
                            preReservation.to_time &&
                            !validInterval()
                        "
                        [(dateTime)]="preReservation.from_time"
                        (dateChange)="updateFromDate($event)"
                        [minDate]="minDate"
                        [initDate]="initDate"
                    ></app-date-time-picker>

                    <div class="separator">
                        <img src="/assets/images/icon_arrow_right.svg" alt="arrow right"/>
                    </div>

                    <app-date-time-picker
                        #dateTimePickerTo
                        [isError]="
                             preReservation.from_time &&
                             preReservation.to_time &&
                             !validInterval()
                        "
                        [(dateTime)]="preReservation.to_time"
                        (dateChange)="updateToDate($event)"
                        [minDate]="minDate"
                        [initDate]="initDate"
                    ></app-date-time-picker>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal-footer">
    <div (click)="activeModal.dismiss()" class="modal-button-link">
        <a>CANCEL</a>
    </div>
    <div [ngClass]="{
            'disabled-link':
                !preReservationForm.valid ||
                !preReservation.from_time ||
                !preReservation.to_time ||
                !validInterval()
         }"
         (click)="
            !preReservationForm.valid ||
                !preReservation.from_time ||
                !preReservation.to_time ||
                !validInterval() ||
                save()
         "
         class="modal-button-link">
        <a>SAVE</a>
    </div>
</div>
