import { Component, OnInit } from '@angular/core';
import { ParkPlaceSelectAdminService } from '../../services/park-place-select-admin.service';
import { ParkingSlot } from '../../classes/ParkingSlot';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpecialFlag } from '../../../../classes/SpecialFlag';
import { SpecialFlagService } from '../../../admin/services/special-flag.service';
import { CONSTS } from '../../../../constants';

@Component({
  selector: 'app-add-flag-to-parking-slot-modal',
  templateUrl: './add-flag-to-parking-slot-modal.component.html',
  styleUrls: ['./add-flag-to-parking-slot-modal.component.scss'],
})
export class AddFlagToParkingSlotModalComponent implements OnInit {
  selectedParkingSlot: ParkingSlot;
  flagList: SpecialFlag[];

  selectedFlags: SpecialFlag[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private parkPlaceSelectAdminService: ParkPlaceSelectAdminService,
    private specialFlagService: SpecialFlagService
  ) {}

  ngOnInit() {
    this.selectedParkingSlot = this.parkPlaceSelectAdminService.getSelectionStatusSnapshot()[0];
    this.selectedFlags = this.selectedParkingSlot.flags;

    this.getFlagList();
  }

  private getFlagList(): void {
    this.specialFlagService.getSpecialFlagList().subscribe({
      next: (flags) => {
        this.flagList = flags;
      },
    });
  }

  selectFlag(flag: SpecialFlag): void {
    let isSelected = false;
    let selectedIndex: number;

    for (let index = 0; index < this.selectedFlags.length; index++) {
      if (this.selectedFlags[index].id === flag.id) {
        isSelected = true;
        selectedIndex = index;
        break;
      }
    }

    if (isSelected) {
      this.selectedFlags.splice(selectedIndex, 1);
    } else {
      this.selectedFlags.push(flag);
    }
  }

  getCheckboxType(flag: SpecialFlag): string {
    let isSelected = false;

    for (let selectedFlag of this.selectedFlags) {
      if (selectedFlag.id === flag.id) {
        isSelected = true;
      }
    }

    return isSelected ? CONSTS.ICON_URL.CHECKBOX_CHECKED : CONSTS.ICON_URL.CHECKBOX_UNCHECKED;
  }

  addSelectedFlagsToParkingSlot(): void {
    this.specialFlagService.saveFlagsForParkingSlot(this.selectedParkingSlot.id, this.selectedFlags).subscribe({
      next: () => {
        this.activeModal.close();
      },
    });
  }
}
