import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams, RowDataTransaction } from 'ag-grid-community';
import { User } from '../../../../../classes/User';
import { LoaderService } from '../../../../../services/loader.service';
import { AdminMenuService } from '../../../services/admin-menu.service';

@Component({
  selector: 'app-ag-status-user-cell-renderer',
  templateUrl: './ag-status-user-cell-renderer.component.html',
  styleUrls: ['./ag-status-user-cell-renderer.component.scss'],
})
export class AgStatusUserCellRendererComponent implements AgRendererComponent {
  user: User;
  params: ICellRendererParams;

  constructor(private adminMenuService: AdminMenuService, private loaderService: LoaderService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refresh(params: ICellRendererParams): boolean {
    return undefined;
  }

  agInit(params: ICellRendererParams): void {
    this.user = params.data;
    this.params = params;
  }

  setUserStatus() {
    setTimeout(() => {
      this.adminMenuService.updateUserStatus(this.user).subscribe((user) => {
        // update row in ag-grid
        let transaction: RowDataTransaction = {
          update: [user],
        };
        this.params.api.applyTransaction(transaction);

        this.loaderService.hideLoader();
      });
    });
  }
}
