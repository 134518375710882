// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  name: 'dev',
  production: false,
  backend_url: 'https://ey-parking-api.attrecto-dev.com/api/',
  firebase: {
    apiKey: 'AIzaSyCfS0ULkqIQolmu54YXravu4BNS7iUDcMU',
    authDomain: 'ey-parking-dev.firebaseapp.com',
    projectId: 'ey-parking-dev',
    storageBucket: 'ey-parking-dev.appspot.com',
    messagingSenderId: '1096382483222',
    appId: '1:1096382483222:web:827ba527b03d8316f6de37',
  },
};
