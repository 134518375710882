import { Component, OnInit } from '@angular/core';
import { FooterCommonComponent } from '../footer-common/footer-common.component';

@Component({
  selector: 'app-footer-login',
  templateUrl: './footer-login.component.html',
  styleUrls: ['./footer-login.component.scss'],
})
export class FooterLoginComponent extends FooterCommonComponent implements OnInit {
  ngOnInit() {
    this.initCommon();
  }
}
