import { Component } from '@angular/core';
import { CONSTS } from '../../../../constants';
import { DateTimePickerEvent } from '../../../shared/components/date-time-picker/classes/DateTimePickerEvent';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent {
  CONSTS = CONSTS;
  selectedReport = CONSTS.REPORTS.NIGHT_STAND;
  selectedDateRange: DateTimePickerEvent;

  onlyValid = false;

  getReportList(rangeDateData: DateTimePickerEvent) {
    this.selectedDateRange = rangeDateData;
  }

  selectReport(report: string) {
    this.selectedReport = report;
  }

  get showInvalidBtnText(): string {
    return this.onlyValid ? 'Show invalidated' : "Don't show invalidated";
  }

  switchShowInvalidated(): void {
    this.onlyValid = !this.onlyValid;
  }
}
