import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ParkPlaceSelectAdminService } from '../../../../../../park-place-select/services/park-place-select-admin.service';
import { ParkingSlot } from '../../../../../../park-place-select/classes/ParkingSlot';

@Component({
  selector: 'app-private-slot-dropdown-list-select',
  templateUrl: './private-slot-dropdown-list-select.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class PrivateSlotDropdownListSelectComponent implements OnInit {
  selectedParkingSlot: ParkingSlot;
  freeParkingSlots: ParkingSlot[] = [];

  @Input() disabled: boolean = false;
  @Input() selectedParkingSlotNumber: number;

  @Output() selectedParkingSlotNumberChange = new EventEmitter<number>();

  constructor(private parkPlaceSelectAdminService: ParkPlaceSelectAdminService) {}

  ngOnInit() {
    this.getFreePrivatePlaces();

    if (this.selectedParkingSlotNumber) {
      this.selectedParkingSlot = { ...new ParkingSlot(), slot_number: this.selectedParkingSlotNumber };
    }
  }

  private getFreePrivatePlaces() {
    this.parkPlaceSelectAdminService.getFreePrivatePlaces().subscribe({
      next: (parkingSlots) => {
        this.freeParkingSlots = parkingSlots;
      },
    });
  }

  selectParkingSlot(selectedSlot: ParkingSlot) {
    this.selectedParkingSlot = selectedSlot;

    this.selectedParkingSlotNumberChange.emit(Number(this.selectedParkingSlot?.slot_number));
  }

  get selectTitle(): string {
    let baseTitle = 'Private Parking Place';

    return this.selectedParkingSlotNumber
      ? baseTitle + `: ${this.selectedParkingSlotNumber.toString()}`
      : baseTitle;
  }

  deleteCurrentValue() {
    this.selectParkingSlot(null);
  }
}
