<div class="inner-footer desktop">
    <div class="reception-call-icon-container">
        <!-- LOGIN -->
        <div class="login-reception-call d-inline-block">
            <app-clickable-image
                    [title]="loginReceptionImage.title"
                    [imageHeight]="_mediumImageSize"
                    [imageSrc]="CONSTS.ICON_URL.RECEPTION_CALL"
                    [imageText]="floorSelectReceptionImage.text"
                    [imageLinkHref]="loginReceptionImage.link"
            ></app-clickable-image>
            <!-- FLOOR-SELECT OR PARK-PLACE-SELECT-->
            <div class="login-reception-call-number d-none d-xl-flex">
                {{ receptionNumber }}
            </div>
        </div>

        <app-version></app-version>
    </div>

    <!-- -----------------------
        * FLOOR-SELECT
        * ------------------------>
    <ng-container *ngIf="isFloorSelect">
        <!-- SHOW MY RESERVATION TEMPORARY ICON -->
        <div *ngIf="showMyReservationNotFinishedIcon"
             class="parking-place-select d-inline-block"
        >
            <app-clickable-image
                    [title]="showMyReservationImage.title"
                    [imageHeight]="_mediumImageSize"
                    [imageSrc]="CONSTS.ICON_URL.SHOW_MY_RESERVATION"
                    [imageText]="showMyReservationImage.text"
                    [showTextOnMobile]="true"
                    (onImageClick)="showMyReservation()"
            ></app-clickable-image>
        </div>

        <!-- SHOW MY RESERVATION PARKED ICON -->
        <div *ngIf="showMyReservationParkedIcon"
             class="parking-place-select d-inline-block"
        >
            <app-clickable-image
                    [title]="showMyReservationImage.title"
                    [imageHeight]="_bigImageSize"
                    [imageSrc]="CONSTS.ICON_URL.PARKED"
                    [imageText]="parkedImage.text"
                    [showTextOnMobile]="true"
                    (onImageClick)="showMyReservation()"
            ></app-clickable-image>
        </div>

        <!-- FAVOURITES-ICON -->
        <div *ngIf="!reservationStatusExist" class="parked d-inline-block">
            <app-clickable-image
                    [title]="favouritesImage.title"
                    [imageHeight]="_mediumImageSize"
                    [imageSrc]="CONSTS.ICON_URL.FAVOURITES"
                    [imageText]="favouritesImage.text"
                    (onImageClick)="openFavouritesModal()"
                    [columnDirection]="true"
            ></app-clickable-image>
        </div>

        <!-- I AM WITH BICYCLE ICON -->
        <div *ngIf="showIamWithBikeIcon">
            <app-clickable-image
                    [title]="bicycleImage.title"
                    [imageHeight]="_smallImageSize"
                    [imageSrc]="CONSTS.ICON_URL.BICYCLE"
                    [imageText]="bicycleImage.text"
                    (onImageClick)="iAmWithBike(true)"
                    [columnDirection]="true"
            ></app-clickable-image>
        </div>
    </ng-container>

    <!-- -----------------------
       * PARKING-PLACE-SELECT
       * ---------------------- -->
    <ng-container *ngIf="isParkPlaceSelect">
        <ng-container *ngIf="!customMode">
            <!-- PICK PARK PLACE SELECT ICON -->
            <div *ngIf="showParkPlaceSelect"
                 class="parking-place-select d-inline-block"
            >
                <app-clickable-image
                        [title]="pickParkingPlaceImage.title"
                        [imageHeight]="_bigImageSize"
                        [imageSrc]="CONSTS.ICON_URL.PICK_PARKING_PLACE"
                        [imageText]="
                            reservationStatus.changeParkingSlot
                              ? pickNewParkingPlaceImage.text
                              : pickParkingPlaceImage.text
                          "
                        [showTextOnMobile]="true"
                        [hoverClass]="'no-hover'"
                ></app-clickable-image>
            </div>

            <!-- PARKED-ICON -->
            <div *ngIf="reservationStatusExist && showParked"
                 class="parked d-inline-block"
            >
                <app-clickable-image
                        [title]="parkedImage.title"
                        [imageHeight]="_bigSmallImageSize"
                        [imageSrc]="CONSTS.ICON_URL.PARKED"
                        [imageText]="parkedImage.text"
                        (onImageClick)="finalizeReservation()"
                ></app-clickable-image>
            </div>
        </ng-container>

        <!-- -----------------------
             * RESERVATION EXIST
             * ---------------------- -->
        <ng-container *ngIf="!favouriteSelect && reservationStatusExist">
            <div class="other-icon-container">
                <!-- SWAP PARKING PLACE ICON -->
                <div *ngIf="showTheyTookMyPlace" class="d-inline-block">
                    <app-clickable-image
                            [title]="changeParkingSlotImage.title"
                            [imageHeight]="_smallImageSize"
                            [imageSrc]="CONSTS.ICON_URL.SWAP_PLACE"
                            [imageText]="changeParkingSlotImage.text"
                            (onImageClick)="changeParkingSlot()"
                            [columnDirection]="true"
                    ></app-clickable-image>
                </div>

                <!-- THEY TOOK MY PLACE ICON -->
                <div *ngIf="showTheyTookMyPlace" class="d-inline-block">
                    <app-clickable-image
                            [title]="theyTookMyPlaceImage.title"
                            [imageHeight]="_smallImageSize"
                            [imageSrc]="CONSTS.ICON_URL.THEY_TOOK_MY_PLACE"
                            [imageText]="theyTookMyPlaceImage.text"
                            (onImageClick)="theyTookMyPlace()"
                            [columnDirection]="true">
                    </app-clickable-image>
                </div>

                <!-- I AM WITH BICYCLE ICON -->
                <div *ngIf="showIamWithBikeIcon && showTheyTookMyPlace"
                     class="d-inline-block">
                    <app-clickable-image
                            [title]="bicycleImage.title"
                            [imageHeight]="_smallImageSize"
                            [imageSrc]="CONSTS.ICON_URL.BICYCLE"
                            [imageText]="bicycleImage.text"
                            (onImageClick)="iAmWithBike(true)"
                            [columnDirection]="true">
                    </app-clickable-image>
                </div>

                <!-- CANCEL RESERVATION ICON -->
                <div *ngIf="showCancel" class="d-inline-block">
                    <app-clickable-image
                            [title]="cancelReservationImage.title"
                            [imageHeight]="_smallImageSize"
                            [imageSrc]="CONSTS.ICON_URL.CANCEL_RESERVATION"
                            [imageText]="cancelReservationImage.text"
                            (onImageClick)="cancelReservation()"
                            [columnDirection]="true"
                    ></app-clickable-image>
                </div>

                <!-- CANCEL SWAP RESERVATION ICON -->
                <div *ngIf="showCancelSwap" class="d-inline-block">
                    <app-clickable-image
                            [title]="cancelReservationChangeImage.title"
                            [imageHeight]="_miniImageSize"
                            [imageSrc]="CONSTS.ICON_URL.CLOSE_WHITE"
                            [imageText]="cancelReservationChangeImage.text"
                            (onImageClick)="cancelChangeReservation()"
                            [columnDirection]="true"
                    ></app-clickable-image>
                </div>
            </div>
        </ng-container>

        <!-- -----------------------
             * FAVOURITE SELECT
             * ---------------------- -->
        <ng-container *ngIf="favouriteSelect">
            <!-- FAVOURITE-PICK-PLACE-ICON -->
            <div class="parked d-inline-block">
                <app-clickable-image
                        [title]="favouriteSelectOkImage.title"
                        [imageHeight]="_mediumImageSize"
                        [imageSrc]="CONSTS.ICON_URL.PICK_PARKING_PLACE_BLUE"
                        [imageText]="favouriteSelectOkImage.text"
                        [disableAnimation]="true"
                ></app-clickable-image>
            </div>

            <div class="other-icon-container">
                <!-- FAVOURITE CANCEL ICON -->
                <div class="d-inline-block">
                    <app-clickable-image
                            [title]="favouriteCancelImage.title"
                            [imageHeight]="_smallImageSize"
                            [imageSrc]="CONSTS.ICON_URL.DELETE_BLACK"
                            [imageText]="favouriteCancelImage.text"
                            (onImageClick)="cancelAddFavourite()"
                            [columnDirection]="true"
                    ></app-clickable-image>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <!-- -----------------------
      * COMMON
      * ---------------------- -->

    <!-- I AM WITH CAR ICON -->
    <div *ngIf="userWithBike && !favouriteSelect"
         [class.parked]="!isFloorSelect"
         [class.d-inline-block]="!isFloorSelect"
    >
        <app-clickable-image
                [title]="carImage.title"
                [imageHeight]="_smallImageSize"
                [imageSrc]="CONSTS.ICON_URL.CAR"
                [imageText]="carImage.text"
                (onImageClick)="iAmWithBike(false)"
                [columnDirection]="true"
        ></app-clickable-image>
    </div>
</div>
