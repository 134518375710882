import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../modules/auth/auth.service';
import { ModalService } from '../../../services/modal.service';
import { CONSTS } from '../../../constants';
import { FloorPlace } from '../../../modules/shared/classes/FloorPlace';
import { ModalConfig } from '../../../modules/shared/classes/ModalConfig';
import { ParkPlaceSelectGarageUserService } from '../../../modules/park-place-select/services/park-place-select-garage-user.service';
import { ParkPlaceSelectAdminService } from '../../../modules/park-place-select/services/park-place-select-admin.service';
import { DeviceService } from '../../../services/device.service';
import { FloorSelectService } from '../../../modules/floor-select/services/floor-select.service';
import { LoaderService } from '../../../services/loader.service';
import { ClickableImageConfig } from '../../../modules/shared/components/clickable-image/classes/clickableImageConfig';
import { FavouritesService } from '../../../modules/floor-select/services/favourites.service';
import { ParkingSlot } from '../../../modules/park-place-select/classes/ParkingSlot';
import { TheyTookMyPlaceResponse } from '../../../modules/park-place-select/services/classes/TheyTookMyPlaceResponse';

@Component({
  selector: 'app-footer-common',
  template: '<ng-content></ng-content>',
})
export class FooterCommonComponent {
  isMobile: boolean;
  pickParkingPlaceImage: ClickableImageConfig;
  loginReceptionImage: ClickableImageConfig;
  showMyReservationImage: ClickableImageConfig;
  theyTookMyPlaceImage: ClickableImageConfig;
  changeParkingSlotImage: ClickableImageConfig;
  cancelReservationChangeImage: ClickableImageConfig;
  cancelReservationImage: ClickableImageConfig;
  parkedImage: ClickableImageConfig;

  mediumImageSize = '4.5rem';
  bigImageSize = '6rem';
  smallImageSize = '3rem';
  miniImageSize = '2rem';

  bigMobileImageSize = '4rem';
  mobileImageSize = '3rem';

  receptionNumber: string;

  CONSTS = CONSTS;

  constructor(
    public router: Router,
    protected parkPlaceSelectGarageUserService: ParkPlaceSelectGarageUserService,
    protected parkPlaceSelectAdminService: ParkPlaceSelectAdminService,
    protected favouriteService: FavouritesService,
    protected modalService: ModalService,
    protected loaderService: LoaderService,
    protected floorSelectService: FloorSelectService,
    public authService: AuthService,
    public deviceService: DeviceService
  ) {
    this.isMobile = this.deviceService.isMobile();
  }

  initCommon() {
    this.pickParkingPlaceImage = {
      text: 'Pick a Parking Place',
      link: '',
    };
    this.loginReceptionImage = {
      text: 'reception<br /><div>' + CONSTS.RECEPTION_PHONE_NUMBER + '</div>',
      link: 'tel:' + CONSTS.RECEPTION_PHONE_NUMBER,
      title: 'call reception',
    };
    this.showMyReservationImage = {
      text: 'Show My Reservation',
      link: '',
      title: 'jump to my reservation',
    };
    this.cancelReservationImage = {
      text: 'cancel reservation',
      link: '',
      title: 'cancel reservation',
    };
    this.parkedImage = {
      text: 'Parked',
      link: '',
      title: 'finalize reservation',
    };
    this.changeParkingSlotImage = {
      text: 'swap parking place',
      link: '',
      title: 'Swap current parking place',
    };
    this.cancelReservationChangeImage = {
      text: 'cancel swap',
      link: '',
      title: 'Cancel swap parking place',
    };

    this.receptionNumber = CONSTS.RECEPTION_SHORT_PHONE_NUMBER;
  }

  get _bigImageSize() {
    return this.isMobile ? this.bigMobileImageSize : this.bigImageSize;
  }

  get _mediumImageSize() {
    return this.isMobile ? this.mobileImageSize : this.mediumImageSize;
  }

  get _smallImageSize() {
    return this.isMobile ? this.mobileImageSize : this.smallImageSize;
  }

  get _miniImageSize() {
    return this.miniImageSize;
  }

  get _bigSmallImageSize() {
    return this.isMobile ? this.mobileImageSize : this.bigImageSize;
  }

  get isLogin() {
    return this.router.url === CONSTS.PAGE_URL.LOGIN;
  }

  get isFloorSelect() {
    return this.router.url === CONSTS.PAGE_URL.FLOOR_SELECT;
  }

  get isParkPlaceSelect() {
    return this.router.url === CONSTS.PAGE_URL.FLOOR[1] || this.router.url === CONSTS.PAGE_URL.FLOOR[2];
  }

  handleTheyTookMyPlaceResult(
    result: TheyTookMyPlaceResponse,
    newReservationDiscardedCallback: (parkingSlot: ParkingSlot) => void
  ) {
    if (result.result) {
      if (result.result === 'new_reservation_needed') {
        let config = new ModalConfig(CONSTS.ICON_URL.THEY_TOOK_MY_PLACE, CONSTS.MODAL.SELECT_NEW_PARKING_PLACE);
        this.modalService.openConfirmModal(config);
      } else if (result.result === 'have_to_leave') {
        let config = new ModalConfig(CONSTS.ICON_URL.THEY_TOOK_MY_PLACE, CONSTS.MODAL.HAVE_TO_LEAVE);
        this.modalService.openConfirmModal(config);
      }
    } else if (result.reservation) {
      let config = new ModalConfig(CONSTS.ICON_URL.THEY_TOOK_MY_PLACE, CONSTS.MODAL.NEW_PARKING_PLACE);
      config.floorPlace = new FloorPlace(
        result.reservation.parking_slot.floor,
        result.reservation.parking_slot.slot_number
      );
      this.modalService.openConfirmModal(config).then(
        () => {
          this.router.navigate([CONSTS.PAGE_URL.FLOOR[result.reservation.parking_slot.floor.toString()]]);
        },
        () => {
          let parkingSlot = result.reservation.parking_slot;
          parkingSlot.reservation = result.reservation;

          newReservationDiscardedCallback(parkingSlot);
        }
      );
    }
  }
}
