<div class="d-flex flex-column h-100">
    <div class="d-flex align-items-center">
        <div>
            <img alt="back-to-login"
                 class="sub-title-back hover-bigger"
                 title="logout"
                 [src]="'/assets/images/logout_white.svg'"
                 (click)="returnToLoginPage()"/>
        </div>

        <div class="sub-title">
            {{CONSTS.RESET_PASSWORD_TEXT}}
        </div>
    </div>

    <div *ngIf="!loading" class="d-flex align-items-center h-100">

        <div>
            <img alt="password-reset-icon"
                 class="password-reset-icon"
                 title="password-reset-icon"
                 [src]="'/assets/images/' + (passwordResetSendSuccessful ? 'success_tick.svg': 'icon_CancelReservation.svg')"/>
        </div>

        <div class="password-reset-message">
            <span *ngIf="!passwordResetSendSuccessful">
                {{ errorMessage }}
            </span>

            <span *ngIf="passwordResetSendSuccessful">
                {{CONSTS.RESET_PASSWORD_ROUTE.RESET_PASSWORD_SEND_SUCCESSFUL}}
            </span>
        </div>
    </div>
</div>
