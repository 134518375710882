import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickableImageComponent } from './components/clickable-image/clickable-image.component';
import { AutoScaleDirective } from './directives/auto-scale.directive';
import { MouseWheelDirective } from './directives/mouse-wheel';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { LoaderComponent } from './components/loader/loader.component';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';
import { FormsModule } from '@angular/forms';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CountDownTimerComponent } from './components/count-down-timer/count-down-timer.component';
import { BrowserModule } from '@angular/platform-browser';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { VerticalScrollDirective } from './directives/vertical-scroll.directive';
import { VersionComponent } from './components/version/version.component';
import { GridExportComponent } from './components/grid-export/grid-export.component';
import { ReplaceConstantsPipe } from './pipes/replace-constants.pipe';
import { LoginAdminFormComponent } from '../login/components/login-admin-form/login-admin-form.component';
import { LoginNewPasswordFormComponent } from '../login/components/login-new-password-form/login-new-password-form.component';
import { LoginUserFormComponent } from '../login/components/login-user-form/login-user-form.component';
import { DropdownListComponent } from './components/dropdown-list/dropdown-list.component';
import { IsInSelectedListPipe } from './components/dropdown-list/pipes/is-in-selected-list.pipe';

@NgModule({
  imports: [CommonModule, FormsModule, BrowserModule, BsDatepickerModule.forRoot()],
  declarations: [
    ClickableImageComponent,
    AutoScaleDirective,
    MouseWheelDirective,
    ConfirmModalComponent,
    LoaderComponent,
    AlertModalComponent,
    ModalHeaderComponent,
    DatePickerComponent,
    CountDownTimerComponent,
    DateTimePickerComponent,
    VerticalScrollDirective,
    VersionComponent,
    GridExportComponent,
    ReplaceConstantsPipe,
    LoginNewPasswordFormComponent,
    LoginUserFormComponent,
    LoginAdminFormComponent,
    DropdownListComponent,
    IsInSelectedListPipe,
  ],
  exports: [
    ClickableImageComponent,
    AutoScaleDirective,
    MouseWheelDirective,
    ConfirmModalComponent,
    LoaderComponent,
    AlertModalComponent,
    DatePickerComponent,
    CountDownTimerComponent,
    ModalHeaderComponent,
    DateTimePickerComponent,
    VerticalScrollDirective,
    VersionComponent,
    FormsModule,
    BrowserModule,
    GridExportComponent,
    ReplaceConstantsPipe,
    LoginAdminFormComponent,
    LoginUserFormComponent,
    LoginNewPasswordFormComponent,
    DropdownListComponent,
  ],
})
export class SharedModule {}
