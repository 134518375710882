import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { LoaderService } from '../../../../services/loader.service';
import { CONSTS } from '../../../../constants';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Helper } from '../../../../helper';

@Component({
  selector: 'app-login-password-reset-call',
  templateUrl: './login-password-reset-call.component.html',
  styleUrls: ['./login-password-reset-call.component.scss'],
})
export class LoginPasswordResetCallComponent implements OnInit {
  @Input() passwordResetSendSuccessful: boolean;

  errorMessage: string;
  loading: boolean;

  protected readonly CONSTS = CONSTS;

  constructor(
    private authService: AuthService,
    private loaderService: LoaderService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.loading = true;
    this.loaderService.showLoader();

    this.callResetPassword();
  }

  returnToLoginPage() {
    this.router.navigate([CONSTS.PAGE_URL.LOGIN]);
  }

  callResetPassword() {
    const token = this.activatedRoute.snapshot.queryParams?.token as string;

    if (token) {
      this.authService.resetPassword(token).subscribe({
        next: () => {
          this.passwordResetSendSuccessful = true;
          this.loaderService.hideLoader();
          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          const errorDetails = Helper.getErrorDetails(error);

          this.errorMessage = CONSTS.ERROR_MESSAGES[errorDetails['message']];
          this.passwordResetSendSuccessful = false;

          this.loaderService.hideLoader();
          this.loading = false;
        },
      });
    }
  }
}
