import { takeWhile } from 'rxjs/operators';
import { Component, Input, OnDestroy } from '@angular/core';
import { ParkingSlot } from '../../../../../classes/ParkingSlot';
import { MapChartCommonComponent } from '../map-chart-common/map-chart-common.component';
import { CONSTS } from '../../../../../../../constants';
import { Subscription, timer } from 'rxjs';
import * as d3 from 'd3';
import * as _ from 'underscore';
import { Reservation } from '../../../../../classes/Reservation';
import { d3Selection } from '../../../../all-pre-reservation-tab/d3-gantt-chart/classes/d3Selection';
import { MapChartIcons } from '../../../../../classes/map-chart-icons';

@Component({
  selector: 'app-map-chart-admin',
  template: '<ng-content></ng-content>',
  styleUrls: [],
})
export class MapChartAdminComponent extends MapChartCommonComponent implements OnDestroy {
  // ADMIN VARIABLES
  @Input() floorNumber: number;

  selectionStatus: Array<ParkingSlot>;

  selectionStatusSub: Subscription;
  refreshParkingSlotSub: Subscription;
  swapPlaceModeSub: Subscription;

  mapStarted: boolean = null;
  swapPlaceMode: boolean;

  init() {
    this.initCommon(this.floorNumber);
    this.initMapCommon();
    this.loaderService.showLoader();

    this.route.params.subscribe((params) => {
      this.floorName = params['floor'];

      if (this.floorNumberIsPageUrl(this.floorNumber)) {
        if (this.mapStarted === null || this.mapStarted === false) {
          if (this.mapStarted === false) {
            this.mapContainer.one('transitionend', () => {
              if (this.floorNumberIsPageUrl(this.floorNumber)) {
                // check again after animation end
                this.startMap();
              }
            });
          } else {
            this.startMap();
          }
        }
      } else {
        this.mapContainer.off('transitionend');
        if (this.mapStarted === true) {
          this.stopMap();
        }
      }
    });

    this.mapStarted = false;
    this.changeDetector.detectChanges();
  }

  startMap() {
    this.initZoomSub();
    this.refreshData = true;

    this.swapPlaceModeSub = this.parkPlaceSelectAdminService.getSwapPlaceMode().subscribe((swapPlaceMode) => {
      this.swapPlaceMode = swapPlaceMode;

      if (!this.swapPlaceMode) {
        this.hideSwapTooltip();
        this.resetSelection();
      }
    });

    this.selectionStatusSub = this.parkPlaceSelectAdminService
      .getSelectionStatus()
      .subscribe((selectionStatus) => {
        this.selectionStatus = selectionStatus;

        if (selectionStatus[0]?.flags?.length) {
          this.tooltip.flags = selectionStatus[0].flags;
        } else {
          this.tooltip.flags = null;
        }

        if (selectionStatus[0]?.private_user) {
          this.tooltip.private_user = selectionStatus[0].private_user;
        } else {
          this.tooltip.private_user = null;
        }

        if (this.selectionStatus.length !== 1) {
          this.setTooltipReservation(null);
          this.hideTooltip();
        } else {
          this.setTooltipReservation(this.selectionStatus[0].reservation);

          // only handle tooltips if selection is on the same page
          if (this.selectionStatus[0].floor === this.floorNumber) {
            if (this.tooltip.reservation || this.tooltip.flags || this.tooltip.private_user) {
              this.showTooltip();
            } else {
              this.hideTooltip();
            }
          } else {
            this.resetSelection(true);
          }
        }
      });

    this.refreshParkingSlotSub = this.parkPlaceSelectAdminService
      .getRefreshParkingSlotsSub()
      .subscribe((parkingSlots) => {
        if (parkingSlots !== null) {
          this.updateParkingSlots(parkingSlots);
        } else {
          this.loaderService.showLoader();
          this.updateAdminMap().then(() => {
            this.loaderService.hideLoader();
          });
        }
      });

    this.updateAdminMap().then(() => {
      this.addTimers();
      this.mapStarted = true;
      this.loaderService.hideLoader();
    });
  }

  stopMap() {
    this.refreshData = false;
    if (this.zoomSub) {
      this.zoomSub.unsubscribe();
    }

    if (this.selectionStatusSub) {
      this.selectionStatusSub.unsubscribe();
    }
    if (this.refreshParkingSlotSub) {
      this.refreshParkingSlotSub.unsubscribe();
    }
    if (this.swapPlaceModeSub) {
      this.swapPlaceModeSub.unsubscribe();
    }
    this.mapStarted = false;

    // save data to service when swap mode enabled
    if (this.swapPlaceMode) {
      this.hideTooltip(this.tooltip, false);
    } else {
      this.resetSelection();
      this.hideTooltip();
    }

    this.hideSwapTooltip();
  }

  getAdminParkingSlots(callback: (parkingSlots: Array<ParkingSlot>) => void, ignoreError: boolean) {
    this.parkPlaceSelectAdminService
      .getAdminParkingSlots(this.floorNumber, ignoreError)
      .subscribe((result: Array<ParkingSlot>) => {
        callback(result);
      });
  }

  updateAdminMap(ignoreError = false) {
    return new Promise((resolve) => {
      this.getAdminParkingSlots((parkingSlots: ParkingSlot[]) => {
        this.updateParkingSlots(parkingSlots);
        this.previousParkingSlots = parkingSlots;

        resolve(null);
      }, ignoreError);
    });
  }

  updateParkingSlots(parkingSlots: ParkingSlot[]) {
    let svgRectGElement = d3.select(this.svgRectGElement.nativeElement);
    let svgElement = d3.select(this.svgGElement.nativeElement);

    for (let i = 0; i < parkingSlots.length; i++) {
      let parkingSlot = parkingSlots[i];

      // first update all parking slots
      // then always update partial parking place update,
      // and if we trigger full parking slots update, only update the modified ones.
      if (
        !this.previousParkingSlots ||
        parkingSlots.length !== this.previousParkingSlots.length ||
        !_.isEqual(parkingSlot, this.previousParkingSlots[i])
      ) {
        let slotNumber = parkingSlot.slot_number;
        let SVGParkingSlot = svgRectGElement.select("[data-name='" + slotNumber + "']");
        let parkingSlotNumber = null;

        if (!SVGParkingSlot.empty()) {
          let licencePNSVGRect = d3.select((SVGParkingSlot.node() as SVGGElement).nextElementSibling);
          let rHeight = parseInt(licencePNSVGRect.attr('height'));
          let rWidth = parseInt(licencePNSVGRect.attr('width'));
          let rx = parseInt(licencePNSVGRect.attr('x'));
          let ry = parseInt(licencePNSVGRect.attr('y'));

          // first initialization
          // add parking place numbers
          if (!this.previousParkingSlots) {
            SVGParkingSlot.style('opacity', '0.5');

            parkingSlotNumber = this.addSVGText(
              svgRectGElement,
              rx + rWidth - 5,
              ry - 5,
              SVGParkingSlot.attr('transform'),
              slotNumber,
              slotNumber.toString(),
              'rgba(255, 255, 255, 0.8)',
              'end',
              '15px',
              slotNumber + '_sn'
            );

            parkingSlotNumber
              .style('cursor', 'pointer')
              .on('mouseover', () => {
                SVGParkingSlot.style('opacity', '1');
              })
              .on('mouseout', () => {
                SVGParkingSlot.style('opacity', '0.5');
              });

            SVGParkingSlot.classed('park-hover-admin', true);
          } else {
            parkingSlotNumber = svgRectGElement.select("text[data-name='" + slotNumber + "_sn']");

            if (this.selectionStatus) {
              // update selection if necessary
              for (let i2 = 0; i2 < this.selectionStatus.length; i2++) {
                if (this.selectionStatus[i2].id === parkingSlot.id) {
                  this.selectionStatus[i2] = parkingSlot;

                  const parkingSlotNode = SVGParkingSlot.node() as HTMLElement;

                  if (this.selectedElement === parkingSlotNode) {
                    if (parkingSlot.reservation) {
                      this.setSelectedParkingSlot(parkingSlotNode, slotNumber);
                      this.setTooltipReservation(parkingSlot.reservation);
                      this.showTooltip();
                    } else {
                      this.hideTooltip();
                    }
                  }
                  break;
                }
              }
            }
          }

          // always update click events to current parking place because of parkingSlot data change
          parkingSlotNumber.on('click', (event: PointerEvent) => {
            this.handleAdminClick(parkingSlot, SVGParkingSlot, svgRectGElement, slotNumber, event);
          });
          SVGParkingSlot.on('click', (event: PointerEvent) => {
            this.handleAdminClick(parkingSlot, SVGParkingSlot, svgRectGElement, slotNumber, event);
          });

          // remove fill color, group or pre-reserved indicator, type indicator, licence_plate_number text and rect
          if (this.previousParkingSlots) {
            this.setElementColorClass(SVGParkingSlot, null);
            let groupOrPreReservedIndicator = svgElement.select("g[data-name='" + slotNumber + "']");
            if (!groupOrPreReservedIndicator.empty()) {
              groupOrPreReservedIndicator.remove();
            }

            let parkingSlotTypeIndicator = svgElement.select("text[data-name='" + slotNumber + "_indicator']");
            if (!parkingSlotTypeIndicator.empty()) {
              parkingSlotTypeIndicator.remove();
            }

            let parkingSlotLPNText = svgRectGElement.select("text[data-name='" + slotNumber + "_lpn']");
            if (!parkingSlotLPNText.empty()) {
              parkingSlotLPNText.remove();
            }

            licencePNSVGRect.style('display', 'none');
          }

          // add parking type indicators
          switch (parkingSlot.type) {
            case CONSTS.PARKING_SLOT_TYPES.NORMAL:
              // nothing done here
              break;
            case CONSTS.PARKING_SLOT_TYPES.PRIVATE:
              this.addSVGText(
                svgElement,
                parseInt(SVGParkingSlot.attr('x')) +
                  parseInt(SVGParkingSlot.attr('width')) -
                  this.TOP_RIGHT_ICON_WIDTH,
                parseInt(SVGParkingSlot.attr('y')) + this.TOP_RIGHT_ICON_HEIGHT,
                SVGParkingSlot.attr('transform'),
                slotNumber,
                'P',
                '#fff',
                'middle',
                '10px',
                slotNumber + '_indicator',
                'this spot is private'
              );
              break;
            case CONSTS.PARKING_SLOT_TYPES.VISITOR:
              this.addSVGText(
                svgElement,
                parseInt(SVGParkingSlot.attr('x')) +
                  parseInt(SVGParkingSlot.attr('width')) -
                  this.TOP_RIGHT_ICON_WIDTH,
                parseInt(SVGParkingSlot.attr('y')) + this.TOP_RIGHT_ICON_HEIGHT,
                SVGParkingSlot.attr('transform'),
                slotNumber,
                'V',
                '#fff',
                'middle',
                '10px',
                slotNumber + '_indicator',
                'this spot is for visitor'
              );
              break;
          }

          if (parkingSlot.pre_reservation_count) {
            this.addCustomIndicator(
              svgElement,
              MapChartIcons.preReservedIndicatorSVG,
              rx + 2,
              ry - 12,
              SVGParkingSlot.attr('transform'),
              slotNumber,
              'this spot has been booked'
            );
          }

          // handle private users
          if (parkingSlot.private_user) {
            this.placePrivateUserIconToSpot(svgElement, SVGParkingSlot, slotNumber);
          }

          // handle special flags
          if (parkingSlot.flags?.length) {
            this.placeSpecialFlagIconToSpot(svgElement, SVGParkingSlot, slotNumber);
          }

          // handle reservations
          if (parkingSlot.reservation) {
            // add multi group indicator
            if (parkingSlot.reservation.multi) {
              this.addCustomIndicator(
                svgElement,
                MapChartIcons.groupIndicatorSVG,
                parseInt(SVGParkingSlot.attr('x')) + this.TOP_LEFT_ICON_WIDTH,
                parseInt(SVGParkingSlot.attr('y')) + this.TOP_LEFT_ICON_HEIGHT,
                SVGParkingSlot.attr('transform'),
                slotNumber,
                'this spot has been group reserved'
              );
            }

            if (!parkingSlot.reservation.is_final) {
              SVGParkingSlot.classed('yellow-fill-color', true);
            } else {
              SVGParkingSlot.classed('cian-blue-fill-color', true);
            }

            // add licence plate number text!
            if (parkingSlot.reservation.user) {
              licencePNSVGRect.style('display', 'block');

              if (parkingSlot.reservation.user.licence_plate_numbers.length > 0) {
                let userLpn = parkingSlot.reservation.user.licence_plate_numbers[0].licence_plate_number;
                let userLpnCutted = '';

                if (userLpn.length > 6) {
                  userLpnCutted = userLpn.slice(0, 4) + '..';
                } else {
                  userLpnCutted = userLpn;
                }

                this.addSVGText(
                  svgRectGElement,
                  rx + rWidth / 2,
                  ry + rHeight / 2,
                  SVGParkingSlot.attr('transform'),
                  slotNumber,
                  userLpnCutted,
                  '#000',
                  'middle',
                  '10px',
                  slotNumber + '_lpn'
                );
              } else {
                this.addSVGText(
                  svgRectGElement,
                  rx + rWidth / 2,
                  ry + rHeight / 2,
                  SVGParkingSlot.attr('transform'),
                  slotNumber,
                  'MISS!',
                  '#000',
                  'middle',
                  '10px',
                  slotNumber + '_lpn'
                );
              }
            }
          } else {
            if (
              parkingSlot.type !== CONSTS.PARKING_SLOT_TYPES.NONEY &&
              parkingSlot.type !== CONSTS.PARKING_SLOT_TYPES.PRIVATE
            ) {
              SVGParkingSlot.classed('dark-green-fill-color', true);
            }
          }
        }
      }
    }

    this.loaderService.hideLoader();
  }

  changeSelection(slotNumber: number, SVGParkingSlot: d3Selection<any>, parkingSlot: ParkingSlot) {
    let isSelected = SVGParkingSlot.classed('selected');
    SVGParkingSlot.classed('selected', !isSelected);

    if (isSelected) {
      let index = this.selectionStatus.indexOf(parkingSlot);
      this.selectionStatus.splice(index, 1);
    } else {
      this.selectionStatus.push(parkingSlot);
    }

    if (this.selectionStatus.length === 1) {
      this.setSelectedParkingSlot(SVGParkingSlot.node(), slotNumber);
      this.setTooltipReservation(this.selectionStatus[0].reservation);

      if (
        this.selectionStatus[0].reservation ||
        this.selectionStatus[0].flags?.length ||
        this.selectionStatus[0].private_user
      ) {
        this.showTooltip();
      } else {
        this.hideTooltip();
      }
    } else {
      this.hideTooltip();
    }
    this.parkPlaceSelectAdminService.setSelectionStatus(this.selectionStatus);
  }

  handleAdminClick(
    parkingSlot: ParkingSlot,
    SVGParkingSlot: d3Selection<any>,
    svgRectGElement: d3Selection<SVGGElement>,
    slotNumber: number,
    event: PointerEvent
  ) {
    if (this.swapPlaceMode) {
      if (
        (parkingSlot.type === CONSTS.PARKING_SLOT_TYPES.NORMAL ||
          parkingSlot.type === CONSTS.PARKING_SLOT_TYPES.VISITOR) &&
        !parkingSlot.reservation
      ) {
        let isSelected = SVGParkingSlot.classed('selected-swap');

        svgRectGElement.selectAll('.selected-swap').classed('selected-swap', false);
        SVGParkingSlot.classed('selected-swap', !isSelected);

        if (!isSelected) {
          this.selectedSwapPlace = parkingSlot;
          this.selectedHtmlSwapPlace = SVGParkingSlot.node();

          this.showTooltip(null, this.swapTooltip, SVGParkingSlot.node());
        } else {
          this.hideSwapTooltip();
        }
      }
    } else {
      if (!event.ctrlKey) {
        if (
          this.selectionStatus.length > 1 ||
          (this.selectionStatus.length === 1 && SVGParkingSlot.node() !== this.selectedElement)
        ) {
          this.selectionStatus = [];
          svgRectGElement.selectAll('.selected').classed('selected', false);
        }

        this.changeSelection(slotNumber, SVGParkingSlot, parkingSlot);
      } else if (
        ((this.selectionStatus.length === 1 && !this.selectionStatus[0].reservation) ||
          this.selectionStatus.length !== 1) &&
        !parkingSlot.reservation
      ) {
        // handle multiple selection, only allow multiple selection on
        // parking slots with no reservation

        this.changeSelection(slotNumber, SVGParkingSlot, parkingSlot);
      }
    }
  }

  addTimers() {
    // refresh parking slots for map intervally
    timer(CONSTS.WS_REFRESH_PARKING_SLOTS_DELAY, CONSTS.WS_REFRESH_PARKING_SLOTS_CALL_INTERVAL)
      .pipe(takeWhile(() => this.refreshData))
      .subscribe(() => {
        this.updateAdminMap(true);
      });
  }

  setTooltipReservation(reservation: Reservation) {
    this.tooltip.reservation = reservation;
    this.swapTooltip.reservation = reservation;
  }

  swapParkingSlot() {
    this.parkPlaceSelectAdminService.swapParkingSlot(this.selectionStatus[0], this.selectedSwapPlace).subscribe();
  }

  hideSwapTooltip() {
    this.hideTooltip(this.swapTooltip, false);
    this.selectedSwapPlace = null;
    this.selectedHtmlSwapPlace = null;
    this.d3RectGElement.selectAll('.selected-swap').classed('selected-swap', false);
  }

  resetSelection(onlyCurrentFloor = false) {
    if (!onlyCurrentFloor) {
      this.parkPlaceSelectAdminService.setSelectionStatus([]);
    }
    this.selectedElement = null;
    this.d3RectGElement.selectAll('.selected').classed('selected', false);
  }

  ngOnDestroy(): void {
    this.destroyCommon();
  }
}
