import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CONSTS } from '../../../../constants';
import { Favourite } from './classes/favourite';
import { ParkPlaceSelectGarageUserService } from '../../../park-place-select/services/park-place-select-garage-user.service';
import { Router } from '@angular/router';
import { FavouritesService } from '../../services/favourites.service';
import { ModalConfig } from '../../../shared/classes/ModalConfig';
import { ModalService } from '../../../../services/modal.service';
import { LoaderService } from '../../../../services/loader.service';
import { DeviceService } from '../../../../services/device.service';
import { Subscription } from 'rxjs';
import { ReservationStatus } from '../../../park-place-select/classes/ReservationStatus';

@Component({
  selector: 'app-favourites-modal',
  templateUrl: './favourites-modal.component.html',
  styleUrls: ['./favourites-modal.component.scss'],
})
export class FavouritesModalComponent implements OnInit, OnDestroy {
  @ViewChild('flagListTooltip', { read: TemplateRef }) flagListTooltip: TemplateRef<any>;

  CONSTS = CONSTS;
  selectedFavourite: Favourite;
  addFavouriteView = false;
  loading = true;

  newSpot: string;
  isMobile: boolean;

  favourites: Favourite[] = [];

  reservationStatus: ReservationStatus;
  reservationStatusSub: Subscription;

  readonly HIDE_SPECIAL_FLAGS_LIMIT = 3;

  constructor(
    public activeModal: NgbActiveModal,
    private ppsGarageUserService: ParkPlaceSelectGarageUserService,
    private router: Router,
    private modalService: ModalService,
    private favouritesService: FavouritesService,
    private loaderService: LoaderService,
    private deviceService: DeviceService
  ) {}

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    this.getFavouritesList();
    this.listenForReservationStatus();
  }

  getFavouritesList() {
    this.favouritesService.getFavourites().subscribe((favourites) => {
      this.favourites = favourites;
      this.addFavouriteView = false;

      setTimeout(() => {
        this.loaderService.hideLoader();
        this.loading = false;
      });
    });
  }

  listenForReservationStatus() {
    this.reservationStatusSub = this.ppsGarageUserService.getReservationStatus().subscribe((reservationStatus) => {
      this.reservationStatus = reservationStatus;
    });
  }

  addFavourite() {
    if (this.newSpot) {
      this.favouritesService.addFavourite(this.newSpot).subscribe(() => {
        this.getFavouritesList();
      });
    }
  }

  removeFavourite(event: MouseEvent, favourite: Favourite) {
    event.stopPropagation();

    this.favouritesService.removeFavourite(favourite.parking_slot.slot_number).subscribe(() => {
      if (
        this.selectedFavourite &&
        favourite.parking_slot.slot_number === this.selectedFavourite.parking_slot.slot_number
      ) {
        this.selectedFavourite = null;
      }

      this.getFavouritesList();
    });
  }

  setAddView(addView: boolean) {
    if (this.allFavouriteIsManually) {
      this.modalService.openConfirmModal(
        new ModalConfig(CONSTS.ICON_URL.FAVOURITES, CONSTS.MODAL.ALL_FAVOURITE_IS_MANUAL)
      );
    } else {
      this.newSpot = null;
      this.addFavouriteView = addView;
    }
  }

  pickPlaceFromMap() {
    this.ppsGarageUserService.setSelectFavouriteParkingPlaceStatus(true);
    this.activeModal.close();
    this.router.navigate([CONSTS.PAGE_URL.FLOOR['1']]);
  }

  reserveSpot() {
    if (!(this.reservationStatus && this.reservationStatus.user.restricted_at)) {
      if (this.selectedFavourite) {
        this.ppsGarageUserService
          .reserveParkingSlot(this.selectedFavourite.parking_slot.slot_number)
          .subscribe(() => {
            this.activeModal.close();
            this.router.navigate([CONSTS.PAGE_URL.FLOOR[this.selectedFavourite.parking_slot.floor]]);
          });
      }
    } else {
      this.showReservationSuspendedModal();
    }
  }

  selectFavourite(favourite: Favourite) {
    if (!favourite.parking_slot.reservation) {
      if (this.selectedFavourite) {
        if (this.selectedFavourite.parking_slot.slot_number !== favourite.parking_slot.slot_number) {
          this.selectedFavourite = favourite;
        } else {
          this.selectedFavourite = null;
        }
      } else {
        this.selectedFavourite = favourite;
      }
    }
  }

  get allFavouriteIsManually() {
    if (this.favourites.length < 5) {
      return false;
    } else {
      for (let fav of this.favourites) {
        if (!fav.manually_added) {
          return false;
        }
      }

      return true;
    }
  }

  isFavouriteSelected(favourite: Favourite): boolean {
    return (
      this.selectedFavourite &&
      this.selectedFavourite.parking_slot.slot_number === favourite.parking_slot.slot_number
    );
  }

  showReservationSuspendedModal() {
    let config = new ModalConfig(CONSTS.ICON_URL.THEY_TOOK_MY_PLACE, CONSTS.MODAL.RESERVATION_SUSPENDED);

    this.modalService.openConfirmModal(config);
  }

  ngOnDestroy(): void {
    if (this.reservationStatusSub) this.reservationStatusSub.unsubscribe();
  }

  getSpotTitle(favourite: Favourite): string {
    return favourite.parking_slot.reservation ? 'This parking spot is already reserved' : 'Select parking spot';
  }

  getUserIconUrl(favourite: Favourite): string {
    return !this.isFavouriteSelected(favourite)
      ? CONSTS.ICON_URL.USER_TRANSPARENT
      : CONSTS.ICON_URL.USER_TRANSPARENT_WHITE;
  }
}
