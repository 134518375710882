import { Routes } from '@angular/router';
import { LoginComponent } from './modules/login/login.component';
import { CONSTS } from './constants';
import { AuthGuard } from './modules/auth/auth.guard';
import { FloorSelectComponent } from './modules/floor-select/floor-select.component';
import { ParkPlaceSelectComponent } from './modules/park-place-select/park-place-select.component';
import { ReleaseParkingSlotComponent } from './modules/park-place-select/components/release-parking-slot/release-parking-slot.component';

export let APP_ROUTES: Routes = [
  {
    path: CONSTS.ROUTES.ADMIN_LOGIN,
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: CONSTS.ROUTES.LOGIN,
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: CONSTS.ROUTES.RESET_PASSWORD,
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: CONSTS.ROUTES.FLOOR_SELECT,
    component: FloorSelectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `${CONSTS.ROUTES.PARK_PLACE_SELECT}/:floor`,
    component: ParkPlaceSelectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `${CONSTS.ROUTES.RELEASE_SLOT}`,
    component: ReleaseParkingSlotComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: CONSTS.ROUTES.LOGIN,
  },
];
