import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appVerticalScroll]',
})
export class VerticalScrollDirective implements OnInit {
  @Input() verticalExpectEl: string = null;

  nativeElement: HTMLElement;

  constructor(private element: ElementRef) {
    this.nativeElement = element.nativeElement;
  }

  scrollVertically(event: Event) {
    const eventTarget = event.target as HTMLElement;

    if (!this.verticalExpectEl || (eventTarget && !eventTarget.classList.contains(this.verticalExpectEl))) {
      const delta = Math.max(-1, Math.min(1, (event as any).wheelDelta || -(event as any).detail));
      this.nativeElement.scrollTop -= delta * 60; // Multiplied by 40
      event.preventDefault();
    }
  }

  ngOnInit() {
    if (this.nativeElement.addEventListener) {
      // IE9, Chrome, Safari, Opera
      this.nativeElement.addEventListener(
        'mousewheel',
        (event: Event) => {
          this.scrollVertically(event);
        },
        { passive: false }
      );
      // Firefox
      this.nativeElement.addEventListener(
        'DOMMouseScroll',
        (event: Event) => {
          this.scrollVertically(event);
        },
        { passive: false }
      );
    } else {
      // IE 6/7/8
      // @ts-ignore
      this.nativeElement.attachEvent('onmousewheel', (event) => {
        this.scrollVertically(event);
      });
    }
  }
}
