<div #preReservationMenuContainer class="pre-reservation-menu-container">
    <div class="pre-reservation-menu-header">
        <div class="header-title">
            <img [src]="CONSTS.ICON_URL.PRE_RESERVATION_BLACK" alt="pre reservation"/>
            BOOKINGS OVERVIEW
        </div>

        <div class="header-close">
            <img class="hover-bigger"
                 title="close admin menu"
                 alt="close tab"
                 (click)="hideTab()"
                 [src]="CONSTS.ICON_URL.BACK_UP"
            />
        </div>
    </div>
    <div class="pre-reservation-menu-content">
        <app-date-picker
            class="date-picker-container"
            (onDateSelect)="getPreReserveList($event)">
        </app-date-picker>

        <div class="gantt-chart-main w-100">
            <div *ngIf="showLoader" class="loader">
                <app-loader [showLoader]="true"></app-loader>
            </div>

            <app-d3-gantt-chart
                *ngIf="showGantt"
                [day]="preReservationsDay"
                [dataList]="chartData.dataList"
                [dataNames]="chartData.slotNumbers"
                [floorNumber]="1"
                [hideXaxisText]="false">
            </app-d3-gantt-chart>
        </div>
    </div>
</div>
