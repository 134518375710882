import { Subscription, timer as observableTimer } from 'rxjs';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-count-down-timer',
  templateUrl: './count-down-timer.component.html',
  styleUrls: ['./count-down-timer.component.scss'],
})
export class CountDownTimerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() format: string = 'HH:mm:ss';
  @Input() endDate: string;

  @Output() onTimeEnd = new EventEmitter();

  time: string;
  timerSub: Subscription;

  ngOnInit() {
    this.initTimer();
  }

  ngOnChanges() {
    this.initTimer();
  }

  initTimer() {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }

    let endTime: number = null;

    if (this.endDate) {
      endTime = moment(this.endDate).tz(moment.tz.guess()).unix();
    }

    if (!endTime) {
      this.time = '-';
    } else {
      this.timerSub = observableTimer(0, 1000).subscribe(() => {
        let currentTime = moment().tz(moment.tz.guess()).unix();
        let diff = endTime - currentTime;

        if (diff <= 0) {
          diff = 0;
          this.onTimeEnd.emit();
          this.timerSub.unsubscribe();
        }

        const tmpTime = moment.duration(diff * 1000);

        this.time = moment.utc(tmpTime.as('milliseconds')).format(this.format);
      });
    }
  }

  ngOnDestroy() {
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
  }
}
