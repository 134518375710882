import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { LicencePlateNumber } from '../../../../../../../classes/LicencePlateNumber';
import { CONSTS } from '../../../../../../../constants';
import { ModalService } from '../../../../../../../services/modal.service';
import ClickEvent = JQuery.ClickEvent;

declare const $: JQueryStatic;

@Component({
  selector: 'app-lpn-dropdown-list-edit',
  templateUrl: './lpn-dropdown-list-edit.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LpnDropdownListEditComponent {
  @Input() dataList: Array<LicencePlateNumber>;
  @Input() isGarageUser: boolean;
  @Input() saveClicked: boolean;
  @Input() isVisitor: boolean;
  @Output() dataListChange = new EventEmitter<Array<LicencePlateNumber>>();

  userClicked: boolean = false;
  selectOpened: boolean = false;
  newLicencePlateNumber: string = '';
  CONSTS = CONSTS;

  constructor(private ref: ElementRef, private modalService: ModalService) {}

  @HostListener('document:click', ['$event'])
  onClick(event: ClickEvent) {
    let checkLPNModal = $('.check_lpn');

    if (!checkLPNModal) {
      if (
        !event.target.classList.contains('plate-delete-icon') &&
        !event.target.classList.contains('plate-add-icon') &&
        !this.ref.nativeElement.contains(event.target)
      ) {
        this.selectOpened = false;
      }
    }
  }

  getSelectTitle() {
    let title = 'Select Plate Numbers';

    if (this.dataList.length === 1) {
      title = this.dataList[0].licence_plate_number;
    } else if (this.dataList.length > 1) {
      title = this.dataList.length + ' Plate Numbers';
    }

    return title;
  }

  checkLPN() {
    this.modalService.checkHungarianPlateNumber(this.newLicencePlateNumber).then(
      () => {
        this.addNewLPN();
      },
      () => {
        // refect ignored
      }
    );
  }

  addNewLPN() {
    let exist = false;
    for (let curItem of this.dataList) {
      if (curItem.licence_plate_number === this.newLicencePlateNumber) {
        exist = true;
        break;
      }
    }

    if (!exist) {
      let newData = new LicencePlateNumber(this.newLicencePlateNumber);

      if (this.dataList.length === 0) {
        newData.primary = true;
      }

      this.dataList.push(newData);
      this.dataListChange.emit(this.dataList);
    }

    this.newLicencePlateNumber = '';
  }

  deleteLPN(index: number) {
    let isPrimary = this.dataList[index].primary;

    this.dataList.splice(index, 1);
    if (isPrimary && this.dataList.length > 0) {
      this.dataList[0].primary = true;
    }

    this.dataListChange.emit(this.dataList);
  }

  setLPNPrimary(index: number) {
    for (let i = 0; i < this.dataList.length; i++) {
      this.dataList[i].primary = i === index;
    }
    this.dataListChange.emit(this.dataList);
  }

  toggleLPNSelect() {
    this.userClicked = true;
    this.selectOpened = !this.selectOpened;
  }
}
