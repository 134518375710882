<div class="select-toggle">
    <div (click)="toggleLPNSelect()"
         class="form-control data-toggle"
         [ngClass]="{
              'has-error':
                (isGarageUser || isVisitor) &&
                (userClicked || saveClicked) &&
                !selectOpened &&
                dataList.length === 0
            }"
    >
        {{ getSelectTitle() }}
        <div class="toggle-icon">
            <img [ngClass]="{ 'd-none': !selectOpened }"
                 [src]="CONSTS.ICON_URL.ARROW_UP"
                 alt="arrow up"
            />
            <img [ngClass]="{ 'd-none': selectOpened }"
                 [src]="CONSTS.ICON_URL.ARROW_DOWN"
                 alt="arrow down"
            />
        </div>
    </div>
    <div class="data-list" [ngClass]="{ 'd-none': !selectOpened }">
        <form #lpnForm="ngForm">
            <div class="lpn-list-item-input">
                <input name="new_licence_plate_number_input"
                       [(ngModel)]="newLicencePlateNumber"
                       [pattern]="CONSTS.PATTERNS.PLATE_NUMBER"
                       required
                       type="text"
                       #new_licence_plate_number="ngModel"
                       class="plate-add-icon"
                       [placeholder]="CONSTS.PLATE_NUMBER_FORMAT"
                       [ngClass]="{ 'has-error': new_licence_plate_number.errors?.pattern }"
                />
                <img [ngClass]="{
                        disabled:
                          new_licence_plate_number.errors?.required ||
                          new_licence_plate_number.errors?.pattern
                     }"
                     (click)="
                          new_licence_plate_number.errors?.required ||
                          new_licence_plate_number.errors?.pattern ||
                          checkLPN()
                      "
                     [src]="CONSTS.ICON_URL.ADD_ICON"
                     alt="add"
                     title="add new licence plate number"
                />
            </div>
        </form>
        <div *ngFor="let plateNumber of dataList; let idx = index"
             class="lpn-list-item">
            {{ plateNumber.licence_plate_number }}

            <img class="plate-delete-icon"
                 title="remove"
                 alt="remove"
                 (click)="deleteLPN(idx)"
                 [src]="CONSTS.ICON_URL.DROPDOWN_REMOVE"
            />

            <div (click)="setLPNPrimary(idx)" title="set to primary" class="img">
                <img [ngClass]="{ 'd-none': !plateNumber.primary }"
                     [src]="CONSTS.ICON_URL.STAR_BLUE"
                     alt="star"
                />
                <img [ngClass]="{ 'd-none': plateNumber.primary }"
                     [src]="CONSTS.ICON_URL.STAR"
                     alt="star"
                />
            </div>
        </div>
    </div>
</div>
