import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloorSelectComponent } from './floor-select.component';
import { FloorSelectorComponent } from './components/floor-selector/floor-selector.component';
import { SharedModule } from '../shared/shared.module';
import { FloorSelectService } from './services/floor-select.service';
import { FavouritesModalComponent } from './components/favourites-modal/favourites-modal.component';
import { FavouritesService } from './services/favourites.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [CommonModule, SharedModule, TooltipModule.forRoot()],
  declarations: [FloorSelectComponent, FloorSelectorComponent, FavouritesModalComponent],
  providers: [FloorSelectService, FavouritesService],
})
export class FloorSelectModule {}
