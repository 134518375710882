import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Role } from '../../../../../../../classes/Role';
import { CONSTS } from '../../../../../../../constants';

@Component({
  selector: 'app-role-dropdown-list-select',
  templateUrl: './role-dropdown-list-select.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class RoleDropdownListSelectComponent {
  @Input() roleList: Array<Role> = [
    { id: 1, slug: 'admin', name: 'Admin' },
    { id: 2, slug: 'receptionist', name: 'Receptionist' },
    { id: 3, slug: 'garage-user', name: 'Garage User' },
    { id: 4, slug: 'visitor', name: 'Visitor' },
  ];
  @Input() saveClicked: boolean;

  @Input() selectedRoles: Role[] = [];
  @Output() selectedRolesChange = new EventEmitter<Role[]>();

  @Input() isVisitor: boolean;
  @Output() isVisitorChange = new EventEmitter<boolean>();

  @Input() isGarageUser: boolean;
  @Output() isGarageUserChange = new EventEmitter<boolean>();

  CONSTS = CONSTS;

  getSelectTitle() {
    let title = 'Select Roles';

    if (this.selectedRoles.length === 1) {
      title = this.selectedRoles[0].name;
    } else if (this.selectedRoles.length > 1) {
      title = this.selectedRoles.length + ' Roles';
    }

    return title;
  }

  checkRole(role: Role) {
    let roleIndex = this.hasRole(role.slug);

    if (roleIndex === -1) {
      if (role.slug === CONSTS.ROLE.ADMIN) {
        this.removeRole(CONSTS.ROLE.VISITOR);
        this.removeRole(CONSTS.ROLE.RECEPTIONIST);

        this.isVisitorChange.emit(false);
      } else if (role.slug === CONSTS.ROLE.RECEPTIONIST) {
        this.removeRole(CONSTS.ROLE.VISITOR);
        this.removeRole(CONSTS.ROLE.ADMIN);

        this.isVisitorChange.emit(false);
      } else if (role.slug === CONSTS.ROLE.VISITOR) {
        this.removeRole(CONSTS.ROLE.RECEPTIONIST);
        this.removeRole(CONSTS.ROLE.ADMIN);
        this.removeRole(CONSTS.ROLE.USER);

        this.isVisitorChange.emit(true);
        this.isGarageUserChange.emit(false);
      } else if (role.slug === CONSTS.ROLE.USER) {
        this.removeRole(CONSTS.ROLE.VISITOR);

        this.isVisitorChange.emit(false);
        this.isGarageUserChange.emit(true);
      }

      this.selectedRoles.push(role);
    } else {
      this.selectedRoles.splice(roleIndex, 1);
      if (role.slug === CONSTS.ROLE.VISITOR) {
        this.isVisitorChange.emit(false);
      } else if (role.slug === CONSTS.ROLE.USER) {
        this.isGarageUserChange.emit(false);
      }
    }

    this.selectedRolesChange.emit(this.selectedRoles);
  }

  hasRole(roleSlug: string): number {
    let hasRole = -1;

    for (let i = 0; i < this.selectedRoles.length; i++) {
      if (this.selectedRoles[i].slug === roleSlug) {
        hasRole = i;
        break;
      }
    }

    return hasRole;
  }

  removeRole(roleSlug: string) {
    let roleIndex = this.hasRole(roleSlug);
    if (roleIndex !== -1) {
      this.selectedRoles.splice(roleIndex, 1);
    }
  }
}
