import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { CONSTS } from '../../constants';
import { AuthService } from './auth.service';

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.authenticated) {
    // disable routes if you are not admin
    if (state.url === CONSTS.PAGE_URL.LOGIN || state.url === CONSTS.PAGE_URL.ADMIN_LOGIN) {
      router.navigate([CONSTS.PAGE_URL.FLOOR_SELECT]);
      return false;
    }
  } else {
    // not allow signIn if you logged in
    if (
      state.url !== CONSTS.PAGE_URL.LOGIN &&
      state.url !== CONSTS.PAGE_URL.ADMIN_LOGIN &&
      !state.url.startsWith(CONSTS.PAGE_URL.RESET_PASSWORD) &&
      !state.url.startsWith(CONSTS.PAGE_URL.RELEASE_SLOT)
    ) {
      router.navigate([CONSTS.PAGE_URL.LOGIN]);
      return false;
    }
  }

  return true;
};
