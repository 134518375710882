import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { CONSTS } from '../../../constants';
import { Observable } from 'rxjs';
import { Alert } from '../classes/Alert';

@Injectable()
export class AlertsService {
  constructor(private httpService: HttpService) {}

  getAlertlist(selectedDate: string): Observable<Array<Alert>> {
    return this.httpService.get(CONSTS.API_END_POINT.ALERT + '/' + selectedDate).pipe(
      map((result) => {
        return <Array<Alert>>result.alerts;
      })
    );
  }

  getAlertlistUnhandled(): Observable<Array<Alert>> {
    return this.httpService.get(CONSTS.API_END_POINT.ALERT).pipe(
      map((result) => {
        return <Array<Alert>>result.alerts;
      })
    );
  }

  resolveAlert(alertId: string): Observable<Alert> {
    return this.httpService.put(CONSTS.API_END_POINT.ALERT + '/' + alertId, { handled: true }).pipe(
      map((result) => {
        return <Alert>result.alert;
      })
    );
  }

  getUnhandledCount(): Observable<number> {
    return this.httpService.get(CONSTS.API_END_POINT.ALERT + '/unhandled-count').pipe(
      map((result) => {
        return result.count;
      })
    );
  }
}
