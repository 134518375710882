import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpService } from '../../../services/http.service';
import { CONSTS } from '../../../constants';
import { LoaderService } from '../../../services/loader.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class ParkPlaceSelectCommonService {
  protected zoomEvent = new Subject<number>();

  constructor(private http: HttpService, private loaderService: LoaderService) {}

  setZoom(factor: number) {
    this.zoomEvent.next(factor);
  }

  getZoom() {
    return this.zoomEvent.asObservable();
  }

  validatePlateNumberExist(licencePlateNumber: string) {
    this.loaderService.showLoader();

    const postData = {
      licence_plate_number: licencePlateNumber,
    };

    return this.http.post(CONSTS.API_END_POINT.LICENCE_PLATE_NUMBER + '/exists', postData).pipe(
      tap(() => {
        this.loaderService.hideLoader();
      })
    );
  }
}
