import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { CONSTS } from '../../../../constants';
import { ParkPlaceSelectAdminService } from '../../services/park-place-select-admin.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { PreReservation } from '../../classes/PreReservation';
import { D3GanttChartComponent } from './d3-gantt-chart/d3-gantt-chart.component';
import { D3GanttChartData } from './d3-gantt-chart/classes/d3GanttChartData';

@Component({
  selector: 'app-all-pre-reservation-tab',
  templateUrl: './all-pre-reservation-tab.component.html',
  styleUrls: ['./all-pre-reservation-tab.component.scss'],
})
export class AllPreReservationTabComponent implements OnDestroy {
  @ViewChild('preReservationMenuContainer', { static: true }) preReservationMenuContainer: ElementRef;
  @ViewChild(D3GanttChartComponent) d3Gantt: D3GanttChartComponent;

  CONSTS = CONSTS;

  getListSub: Subscription;

  showLoader: boolean = true;
  showGantt: boolean = false;
  tabOpened: boolean = false;

  preReservationsDay: string;
  preReservations: Array<PreReservation> = [];
  lastSelectedDate = new Date();

  chartData: D3GanttChartData = {
    slotNumbers: [],
    dataList: [],
  };

  constructor(private parkPlaceSelectAdminService: ParkPlaceSelectAdminService) {}

  getPreReserveList(selectedDate: Date) {
    // add tabOpened variable to prevent load data when tab is not visible. (date-picker triggers this automatically on init)
    if (this.tabOpened) {
      this.showLoader = true;

      this.preReservationsDay = moment(selectedDate).format(CONSTS.DATE_FORMAT);

      this.clearGetListSub();
      this.getListSub = this.parkPlaceSelectAdminService
        .getAllPreReservationsByParkingSlots(this.preReservationsDay)
        .subscribe((preReservations: Array<PreReservation>) => {
          this.preReservations = preReservations;

          this.chartData.slotNumbers = [];
          this.chartData.dataList = [];

          let tmpParkingSlotNumbers: { [key: string]: boolean } = {};
          let secondFloor = false;

          for (let curPreReservation of this.preReservations) {
            let slotNumber = curPreReservation.parking_slot.slot_number;
            let floor = curPreReservation.parking_slot.floor;

            if (!secondFloor && floor === 2) {
              secondFloor = true;

              this.chartData.slotNumbers.push(2000);
            }

            if (!tmpParkingSlotNumbers[slotNumber]) {
              this.chartData.slotNumbers.push(slotNumber);
              tmpParkingSlotNumbers[slotNumber] = true;
            }

            this.chartData.dataList.push({
              from_time: new Date(curPreReservation.from_time),
              to_time: new Date(curPreReservation.to_time),
              data: curPreReservation,
              slot_number: slotNumber,
            });
          }

          if (!secondFloor) {
            this.chartData.slotNumbers.push(2000);
          }

          this.showLoader = false;

          if (this.showGantt) {
            this.d3Gantt.reDrawChart(this.chartData.dataList, this.chartData.slotNumbers, this.preReservationsDay);
          }

          this.showGantt = true;
        });

      this.lastSelectedDate = selectedDate;
    }
  }

  openTab() {
    this.showLoader = true;
    this.tabOpened = true;
    this.preReservationMenuContainer.nativeElement.style.top = 0;

    setTimeout(() => {
      this.getPreReserveList(this.lastSelectedDate);
    }, 501);
  }

  hideTab() {
    this.tabOpened = false;
    this.preReservationMenuContainer.nativeElement.style.top = '-100%';
  }

  clearGetListSub() {
    if (this.getListSub) {
      this.getListSub.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.clearGetListSub();
  }
}
