<div (click)="toggleSelect()"
     class="form-control data-toggle d-flex align-items-center justify-content-center"
     [class.disabled]="disabled"
     [class.has-error]="hasError && !selectOpened">

    {{ title }}

    <img *ngIf="hasResetButton && selectedElements[0]"
         (click)="onResetClicked($event)"
         [src]="CONSTS.ICON_URL.CLOSE"
         class="ml-2 reset-value"
         alt="close_icon">

    <div class="toggle-icon">
        <img [ngClass]="{ 'd-none': !selectOpened }"
             [src]="CONSTS.ICON_URL.ARROW_UP"
             alt="up"/>

        <img [ngClass]="{ 'd-none': selectOpened }"
             [src]="CONSTS.ICON_URL.ARROW_DOWN"
             alt="down"/>
    </div>
</div>
<div [class.d-none]="!selectOpened" class="data-list">
    <div *ngFor="let item of elementList;" class="lpn-list-item">

        {{ item[labelKey] }}

        <div (click)="onElementClick(item)" class="img">

            <img [class.d-none]="!(selectedElements | isInSelectedList: item)"
                 [src]="CONSTS.ICON_URL.RADIO_CHECKED"
                 alt="radio checked"/>

            <img [class.d-none]="selectedElements | isInSelectedList: item"
                 [src]="CONSTS.ICON_URL.RADIO_UNCHECKED"
                 alt="radio unchecked"/>
        </div>
    </div>
</div>
