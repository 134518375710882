import { GridOptions, ICellRendererParams } from 'ag-grid-community';
import { Debug } from '../classes/Log';
import { LogTableCommonColumns } from '../classes/LogTableCommonColumns';
import * as moment from 'moment';
import { LogTableComponent } from '../components/log-table/log-table.component';
import { LogTableConfigUtil } from '../../../classes/LogTableConfigUtil';

export class DebugLogTableConfig {
  static getGridOptions(instance: LogTableComponent): GridOptions {
    return {
      ...LogTableConfigUtil.getCommonOptions<Debug>(instance),
      onCellMouseOver: (params) => {
        $(params.event.target).find('.cell-tooltip').tooltip('show');
      },
      onCellMouseOut: (params) => {
        $(params.event.target).find('.cell-tooltip').tooltip('dispose');
      },
      columnDefs: LogTableCommonColumns.concat([
        {
          headerName: 'Details',
          field: 'details',
          resizable: true,
          sortable: true,
          cellRenderer: (params: ICellRendererParams) => {
            return (
              "<span class='cell-tooltip' data-placement='left' title='" +
              params.value +
              "'>" +
              params.value +
              '</span>'
            );
          },
          cellClass: 'col-grey-color',
        },
        {
          headerName: 'Time',
          field: 'created_at',
          resizable: true,
          sortable: true,
          width: 200,
          suppressSizeToFit: true,
          cellRenderer: (params: ICellRendererParams) => {
            return moment(params.value).format('MM-DD HH:mm');
          },
        },
      ]),
    };
  }
}
