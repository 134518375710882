<div *ngIf="logCategories" class="selector-container">
    <div class="selector"
         *ngFor="let category of logCategories"
         [class.active]="category.id === selectedCategory?.id"
         (click)="selectLogCategory(category)">
        {{ category.name }}
    </div>
</div>

<app-date-picker
    (onDateSelect)="selectDate($event)"
    [maxRangeInMonth]="MAX_LOG_RANGE_MONTH"
    [rangePicker]="true">

    <div filterElement class="filter-logs-list-container">
        <input class="filter-list"
               type="text"
               title="search in list"
               placeholder="Search"
               [(ngModel)]="filterValue"
               name="filterUserList"/>
    </div>

    <app-grid-export
        [csvName]="csvName"
        [allColumns]="true"
        class="logs-grid-export">
    </app-grid-export>
</app-date-picker>

<div>
    <app-log-table
        *ngIf="selectedCategory"
        [fromDate]="fromDate"
        [category]="selectedCategory"
        [filterText]="filterValue"
        [toDate]="toDate">
    </app-log-table>
</div>
