import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { CONSTS } from '../constants';

@Injectable()
export class HttpService {
  constructor(private http: HttpClient) {}

  generateFullUrl(url: string, urlParam: string = null) {
    let fullUrl = environment.backend_url + url;
    if (urlParam) {
      fullUrl += '&' + urlParam;
    }
    return fullUrl;
  }

  generateUrlQueryParams(urlParams: { [key: string]: string } = null): HttpParams {
    let params = new HttpParams();

    if (urlParams) {
      Object.keys(urlParams).forEach((property: string) => {
        if (property in urlParams) {
          params = params.append(property, urlParams[property] as string);
        }
      });
    }

    return params;
  }

  generateIgnoreHeader(ignoreError: boolean) {
    let config: any = undefined;
    if (ignoreError) {
      config = { headers: {} };
      config['headers'][CONSTS.HTTP.IGNORE_ERROR] = String(ignoreError);
    }

    return config;
  }

  get(
    url: string,
    urlParam: string = null,
    ignoreError = false,
    urlQueryParams: { [key: string]: string } = null
  ): Observable<any> {
    return this.http.get(this.generateFullUrl(url, urlParam), {
      ...this.generateIgnoreHeader(ignoreError),
      params: this.generateUrlQueryParams(urlQueryParams),
    });
  }

  post(
    url: string,
    data: any,
    headers: HttpHeaders = null,
    urlQueryParams: { [key: string]: string } = null
  ): Observable<any> {
    return this.http.post(this.generateFullUrl(url), data, {
      headers,
      params: this.generateUrlQueryParams(urlQueryParams),
    });
  }

  put(url: string, data: any): Observable<any> {
    return this.http.put(this.generateFullUrl(url), data);
  }

  patch(url: string, data: any): Observable<any> {
    return this.http.patch(this.generateFullUrl(url), data);
  }

  delete(url: string): Observable<any> {
    return this.http.delete(this.generateFullUrl(url));
  }
}
