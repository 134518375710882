import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { LogTableComponent } from '../components/logs/components/log-table/log-table.component';
import { GetRowIdParams, GridOptions } from 'ag-grid-community';

export class LogTableConfigUtil {
  public static getCommonOptions<T extends { id: number }>(instance: LogTableComponent): GridOptions {
    return {
      suppressDragLeaveHidesColumns: true,
      loadingOverlayComponent: LoaderComponent,
      overlayNoRowsTemplate: '<span class="overlay-no-rows">Nothing to display</span>',
      cacheQuickFilter: true,
      rowData: [] as T[],
      rowHeight: 50,
      headerHeight: 50,
      getRowId: (data: GetRowIdParams) => {
        const rowData = data.data as T;
        return rowData.id.toString();
      },
      onGridReady: () => {
        if (instance.gridOptions.api) {
          instance.gridOptions.api.sizeColumnsToFit();
        }
      },
      onGridSizeChanged: () => {
        if (instance.gridOptions.api) {
          instance.gridOptions.api.sizeColumnsToFit();
        }
      },
    };
  }
}
