import { Injectable } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { CONSTS } from '../../../constants';

@Injectable()
export class FloorSelectService {
  constructor(private httpService: HttpService) {}

  getFreePlaceCount(ignoreError = false) {
    return this.httpService.get(CONSTS.API_END_POINT.PARKING_SLOT + '/free-places', null, ignoreError);
  }

  getFreePlaceAdminCount(ignoreError = false) {
    return this.httpService.get(CONSTS.API_END_POINT.PARKING_SLOT + '/free-places/all', null, ignoreError);
  }
}
