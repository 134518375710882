import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ParkingSlot } from '../../classes/ParkingSlot';
import { LicencePlateNumber } from '../../../../classes/LicencePlateNumber';
import { Option } from '../../../../classes/Option';
import { ParkPlaceSelectAdminService } from '../../services/park-place-select-admin.service';
import { User } from '../../../../classes/User';
import { CONSTS } from '../../../../constants';
import { ModalService } from '../../../../services/modal.service';
import { LoaderService } from '../../../../services/loader.service';
import { ARMDropdownOptions } from './classes/ARMDropdownOptions';
import { ARMSelectedIds } from './classes/ARMSelectedIds';
import { ARMHelperObject } from './classes/ARMHelperObject';
import { ARMRoleName } from './classes/ARMRoleName';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-admin-reservation-modal',
  templateUrl: './admin-reservation-modal.component.html',
  styleUrls: ['./admin-reservation-modal.component.scss'],
})
export class AdminReservationModalComponent implements OnInit {
  @ViewChild('userForm') userForm: NgForm;

  @Input() data: ParkingSlot;

  initialSelectedUser: User;

  userTypeSelect = 'user-type-select';
  visitorSelect = 'visitor-select';
  garageUserSelect = 'garage-user-select';

  newVisitor: boolean = false;
  CONSTS = CONSTS;

  showContent: string;

  list: ARMDropdownOptions = {
    garageUser: {
      userList: [],
      LPNList: [],
    },
    visitor: {
      userList: [],
      LPNList: [],
    },
  };

  selectedIds: ARMSelectedIds = {
    garageUser: {
      userId: '',
      LPNId: '',
    },
    visitor: {
      userId: '',
      LPNId: '',
    },
  };

  helperObject: ARMHelperObject = {
    garageUser: {
      userIds: {},
      LPNIds: {},
    },
    visitor: {
      userIds: {},
      LPNIds: {},
    },
  };

  selectedUser: User = new User();

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: ModalService,
    private parkPlaceSelectAdminService: ParkPlaceSelectAdminService,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.showContent = this.userTypeSelect;
    this.loaderService.showLoader();

    this.parkPlaceSelectAdminService.getUsers().subscribe((users: Array<User>) => {
      for (let user of users) {
        let userId = user.id.toString();
        let roleName: ARMRoleName = null;

        // only fill with valid users (you can't select temporary created visitor users)
        if (
          user.reservations_count === 0 &&
          !user.auto_created &&
          user.name &&
          user.licence_plate_numbers.length > 0 &&
          user.phone
        ) {
          for (let curRole of user.roles) {
            if (curRole.slug === CONSTS.ROLE.VISITOR) {
              roleName = ARMRoleName.Visitor;
              break;
            } else if (curRole.slug === CONSTS.ROLE.USER) {
              roleName = ARMRoleName.GarageUser;
              break;
            }
          }

          if (roleName) {
            let plateOptions = user.licence_plate_numbers.map((plateNumber: LicencePlateNumber) => {
              this.helperObject[roleName].LPNIds[plateNumber.id] = userId;

              return new Option(plateNumber.licence_plate_number, plateNumber.id.toString());
            });

            this.list[roleName].LPNList = this.list[roleName].LPNList.concat(plateOptions);
            this.list[roleName].userList.push(new Option(user.name, userId));
            this.helperObject[roleName].userIds[userId] = user;
          }
        }
      }
      this.list['visitor'].userList.unshift(new Option('+ NEW VISITOR', null));

      this.loaderService.hideLoader();
    });
  }

  getListName(): ARMRoleName {
    if (this.isVisitorSelect) {
      return ARMRoleName.Visitor;
    } else {
      return ARMRoleName.GarageUser;
    }
  }

  selectUser(option: Option) {
    const listName = this.getListName();
    if (option) {
      if (option.value === null) {
        this.newVisitor = true;
        this.selectedUser = new User();
      } else {
        this.newVisitor = false;

        this.selectedUser = { ...this.helperObject[listName].userIds[this.selectedIds[listName].userId] };
        this.selectedIds[listName].LPNId = this.selectedUser?.licence_plate_numbers[0].id.toString();
      }
    }
  }

  selectUserByPlate(option: Option) {
    const listName = this.getListName();

    if (option) {
      this.selectedIds[listName].userId = this.helperObject[listName].LPNIds[option?.value];
      this.selectedUser = { ...this.helperObject[listName].userIds[this.selectedIds[listName].userId] };
    }
  }

  reserveParkingSlot(isFinal: boolean) {
    let isNewVisitor = this.isVisitorSelect && this.newVisitor;
    let multi = this.data.reservation && this.data.reservation.multi;

    if (isNewVisitor) {
      this.modalService.checkHungarianPlateNumber(this.selectedUser.licence_plate_number).then(
        () => {
          this.reserve(isFinal, isNewVisitor, multi);
        },
        () => {
          // refect ignored
        }
      );
    } else {
      this.reserve(isFinal, isNewVisitor, multi);
    }
  }

  reserve(is_final: boolean, isNewVisitor: boolean, multi: boolean) {
    if (isNewVisitor) {
      if (multi) {
        this.parkPlaceSelectAdminService
          .reserveNewVisitorMultiParkingSlot(this.data, this.selectedUser)
          .subscribe(() => {
            this.activeModal.close();
          });
      } else {
        this.parkPlaceSelectAdminService
          .reserveNewVisitorParkingSlot(this.data, this.selectedUser, is_final)
          .subscribe(() => {
            this.activeModal.close();
          });
      }
    } else {
      if (multi) {
        this.parkPlaceSelectAdminService.reserveMultiParkingSlot(this.data, this.selectedUser.id).subscribe(() => {
          this.activeModal.close();
        });
      } else {
        this.parkPlaceSelectAdminService
          .reserveParkingSlot(this.data, this.selectedUser.id, is_final)
          .subscribe(() => {
            this.activeModal.close();
          });
      }
    }
  }

  resetReserveForm() {
    this.userForm.reset();
  }

  showUserTypeSelect() {
    this.showContent = this.userTypeSelect;
    this.resetReserveForm();
  }

  showVisitorSelect() {
    this.showContent = this.visitorSelect;
  }

  showGarageUserSelect() {
    this.showContent = this.garageUserSelect;
  }

  get isGarageUserSelect() {
    return this.showContent === this.garageUserSelect;
  }

  get isVisitorSelect() {
    return this.showContent === this.visitorSelect;
  }

  get isUserTypeSelect() {
    return this.showContent === this.userTypeSelect;
  }

  get isUserExist() {
    return (
      this.selectedUser &&
      this.selectedUser.name &&
      this.selectedUser.phone &&
      (this.selectedUser.licence_plate_numbers || this.selectedUser.licence_plate_number)
    );
  }
}
