import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { CONSTS } from '../../../../constants';

@Component({
  selector: 'app-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss'],
})
export class DropdownListComponent<T extends { [id: string]: string | number }> {
  @Input() elementList: T[];
  @Input() selectedElements: T[] = [];
  @Input() labelKey: keyof T;
  @Input() hasError: boolean;
  @Input() title: string;
  @Input() hasResetButton: boolean = false;
  @Input() disabled: boolean = false;

  @Output() elementClicked = new EventEmitter<T>();
  @Output() resetClicked = new EventEmitter();

  selectOpened: boolean = false;

  protected readonly CONSTS = CONSTS;

  constructor(private ref: ElementRef) {}

  @HostListener('document:click', ['$event'])
  clickedOutSide(event: MouseEvent) {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.selectOpened = false;
    }
  }

  onElementClick(item: T) {
    if (!this.disabled) {
      this.elementClicked.emit(item);
    }
  }

  toggleSelect() {
    if (!this.disabled) {
      this.selectOpened = !this.selectOpened;
    }
  }

  onResetClicked(event: MouseEvent) {
    if (!this.disabled) {
      event.stopPropagation();
      this.resetClicked.emit();
    }
  }
}
