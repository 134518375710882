<form #userResetPasswordForm=ngForm>
    <div class="title">IT'S YOUR FIRST LOGIN</div>
    <div class="sub-title">Please set your password</div>

    <img alt="logout"
         class="password-reset-logout hover-bigger"
         title="logout"
         (click)="logout()"
         [src]="'/assets/images/icon_white_logout.svg'"/>

    <div class="form-group password-reset-info-group">
        <input [ngClass]="{ 'has-error': newPasswordHasError }"
               #new_password="ngModel"
               type="password"
               class="form-control"
               id="new_password"
               name="new_password"
               required
               placeholder="your new password"
               [pattern]="CONSTS.PATTERNS.PASSWORD"
               [(ngModel)]="userResetPasswordData.password"/>

        <img (click)="openPasswordTooltip(passwordResetTooltip)"
             [src]="CONSTS.ICON_URL.INFO"
             class="password-reset-info"
             alt="password reset"/>
    </div>

    <div class="form-group">
        <input [ngClass]="{ 'has-error': newPasswordConfirmationHasError }"
               #new_password_confirm="ngModel"
               type="password"
               class="form-control"
               id="new_password_confirm"
               name="new_password_confirm"
               required
               placeholder="confirm your new password"
               [pattern]="CONSTS.PATTERNS.PASSWORD"
               [(ngModel)]="userResetPasswordData.password_confirm"/>
    </div>

    <button [disabled]="!userResetPasswordForm.valid || areUserPasswordsSame"
            (click)="changePassword()"
            class="btn btn-primary btn-block">
        {{CONSTS.FORM.SUBMIT_TITLE}}
    </button>

    <ng-template #passwordResetTooltip>
        <div class="modal-body password-tooltip">
            <img [src]="CONSTS.ICON_URL.INFO" alt="info"/>

            <p>
                {{CONSTS.PASSWORD_RESET_HINT_MODAL.PASSWORD_VALIDATION_HINT_TEXT}}
            </p>
        </div>
    </ng-template>
</form>
