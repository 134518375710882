import { first, takeWhile } from 'rxjs/operators';
import { Component, Input, OnDestroy } from '@angular/core';
import { FloorPlace } from '../../../../../../shared/classes/FloorPlace';
import { ParkingSlot } from '../../../../../classes/ParkingSlot';
import { ReservationStatus } from '../../../../../classes/ReservationStatus';
import { CONSTS } from '../../../../../../../constants';
import { ModalConfig } from '../../../../../../shared/classes/ModalConfig';
import { MapChartCommonComponent } from '../map-chart-common/map-chart-common.component';
import { Subscription, timer } from 'rxjs';
import * as d3 from 'd3';
import * as _ from 'underscore';
import * as Hammer from 'hammerjs';
import { d3Selection } from '../../../../all-pre-reservation-tab/d3-gantt-chart/classes/d3Selection';
import { ParkingSlotResponse } from '../../../../../classes/ParkingSlotResponse';

@Component({
  selector: 'app-map-chart',
  template: '<ng-content></ng-content>',
  styleUrls: [],
})
export class MapChartGarageUserComponent extends MapChartCommonComponent implements OnDestroy {
  @Input() floorNumber: number;

  reservationStatus: ReservationStatus;

  reservationStatusSub: Subscription;
  refreshParkingSlotSub: Subscription;
  favouriteSelectSub: Subscription;
  userWithBikeSub: Subscription;

  mapStarted: boolean = null;
  hoveredSlotNumber: d3Selection<any>;
  userWithBike: boolean;
  favouriteSelectMode: boolean;
  freePlaceCount: number;

  currentUserFlagIds: { [key: number]: boolean } = {};

  init() {
    let that = this;

    this.loaderService.showLoader();
    this.initCommon(this.floorNumber);
    this.initMapCommon();

    this.listenUserWithBikeChanges();
    this.listenFavouriteModeChanges();

    // add double tap event if not desktop
    if (!this.deviceService.isDesktop()) {
      const hammerManager = new Hammer.Manager(that.d3RectGElement.node());
      hammerManager.add(
        new Hammer.Tap({
          event: 'doubletap',
          taps: 2,
          interval: 1000,
        })
      );

      hammerManager.on('doubletap', (a) => {
        that.handleHammerJSDoubleClick(a.target);
      });
    }

    this.route.params.subscribe((params) => {
      this.floorName = params['floor'];

      if (this.floorNumberIsPageUrl(this.floorNumber)) {
        if (this.mapStarted === null || this.mapStarted === false) {
          if (this.mapStarted === false) {
            this.mapContainer.one('transitionend', () => {
              if (this.floorNumberIsPageUrl(this.floorNumber)) {
                // check again after animation end
                this.startMap();
              }
            });
          } else {
            this.startMap();
          }
        }
      } else {
        this.mapContainer.off('transitionend');
        if (this.mapStarted === true) {
          this.stopMap();
        }
      }
    });

    this.mapStarted = false;
    this.changeDetector.detectChanges();
  }

  startMap() {
    this.initZoomSub();
    this.refreshData = true;

    // handle all reservationState change!
    this.reservationStatusSub = this.parkPlaceSelectGarageUserService
      .getReservationStatus()
      .subscribe((reservationStatus) => {
        // handle parking place reservation change. reset old parking place.
        if (this.reservationStatus && this.reservationStatus.exist) {
          if (
            !reservationStatus.exist ||
            (reservationStatus.exist &&
              this.reservationStatus.parking_slot.slot_number !== reservationStatus.parking_slot.slot_number)
          ) {
            this.updateUserMap();
          }
        }

        this.reservationStatus = reservationStatus;

        this.updateReservationPlace();
      });

    this.refreshParkingSlotSub = this.parkPlaceSelectGarageUserService
      .getRefreshParkingSlotsSub()
      .subscribe((parkingSlots) => {
        this.updateParkingSlots(parkingSlots);
      });

    this.updateUserMap().then(() => {
      this.parkPlaceSelectGarageUserService
        .getCurrentReservation(true)
        .pipe(first())
        .subscribe(() => {
          this.loaderService.hideLoader();
        });

      this.addTimers();
      this.mapStarted = true;
    });
  }

  listenUserWithBikeChanges() {
    this.parkPlaceSelectGarageUserService.getUserWithBikeStatus().subscribe((userWithBike) => {
      this.userWithBike = userWithBike;
    });
  }

  listenFavouriteModeChanges() {
    this.parkPlaceSelectGarageUserService.getSelectFavouriteParkingPlaceStatus().subscribe((status) => {
      if (this.favouriteSelectMode !== status) {
        this.favouriteSelectMode = status;
        this.updateUserMap();
      }
    });
  }

  updateCurrentReservationWithDelay() {
    setTimeout(() => {
      this.parkPlaceSelectGarageUserService.getCurrentReservation(true, true).subscribe();
    }, 2000);
  }

  updateUserMap(ignoreError = false) {
    return new Promise((resolve) => {
      // get parking slots from server
      this.getGarageUserParkingSlots((parkingSlots: ParkingSlot[]) => {
        this.updateParkingSlots(parkingSlots);
        this.previousParkingSlots = parkingSlots;

        resolve(null);
      }, ignoreError);
    });
  }

  updateParkingSlots(parkingSlots: ParkingSlot[]) {
    let svgRectGElement = d3.select(this.svgRectGElement.nativeElement);
    let svgElement = d3.select(this.svgGElement.nativeElement);

    // update all parking place on map based on the result
    for (let i = 0; i < parkingSlots.length; i++) {
      let parkingSlot = parkingSlots[i];

      // first update all parking slots
      // then always update partial parking place update,
      // and if we trigger full parking slots update, only update the modified ones.
      if (
        !this.previousParkingSlots ||
        parkingSlots.length !== this.previousParkingSlots.length ||
        !_.isEqual(parkingSlot, this.previousParkingSlots[i])
      ) {
        let slotNumber = parkingSlot.slot_number;
        let SVGParkingSlot: d3Selection<any> = this.d3RectGElement.select("[data-name='" + slotNumber + "']");
        let parkingSlotNumber: d3Selection<SVGTextElement> = null;

        if (!SVGParkingSlot.empty()) {
          if (!this.previousParkingSlots) {
            // first time initialization: add parking place numbers
            SVGParkingSlot.style('opacity', '0.5');
            let licencePNSVGRect = d3.select((SVGParkingSlot.node() as HTMLElement).nextElementSibling);

            parkingSlotNumber = this.addSVGText(
              svgRectGElement,
              parseInt(licencePNSVGRect.attr('x')) + parseInt(licencePNSVGRect.attr('width')) - 5,
              parseInt(licencePNSVGRect.attr('y')) - 5,
              SVGParkingSlot.attr('transform'),
              slotNumber,
              slotNumber.toString(),
              'rgba(255, 255, 255, 0.8)',
              'end',
              '15px'
            );
          } else {
            parkingSlotNumber = this.d3RectGElement.select("text[data-name='" + slotNumber + "']");
          }

          // if this is update then remove all classes and events from this changed element
          if (this.previousParkingSlots) {
            this.resetParkingPlace(SVGParkingSlot, parkingSlotNumber);
          }

          SVGParkingSlot.datum(parkingSlot);
          parkingSlotNumber.datum(parkingSlot);

          this.authService.currentUser.flags.forEach((flag) => {
            this.currentUserFlagIds[flag.id] = true;
          });

          parkingSlotNumber.on('click', () => {
            this.handleGarageUserClick(parkingSlot, SVGParkingSlot, svgRectGElement, slotNumber);
          });
          SVGParkingSlot.on('click', () => {
            this.handleGarageUserClick(parkingSlot, SVGParkingSlot, svgRectGElement, slotNumber);
          });

          // handle normal parking slots, all other parking slots will be grey
          if (
            parkingSlot.type === CONSTS.PARKING_SLOT_TYPES.NORMAL &&
            (!this.noMatchingSpecialFlags(parkingSlot) || this.freePlaceCount === 0)
          ) {
            if (parkingSlot.reservation_count) {
              if (!parkingSlot.reservation) {
                SVGParkingSlot.classed('reserved-fill-color', true);
              }
            } else {
              SVGParkingSlot.classed('dark-green-fill-color', true);

              if (!(this.favouriteSelectMode && parkingSlot.favourites_count)) {
                this.makeParkingSlotSelectable(SVGParkingSlot, parkingSlotNumber);
              }
            }

            if (this.favouriteSelectMode) {
              if (parkingSlot.favourites_count) {
                SVGParkingSlot.classed('dark-green-fill-color', false);
                SVGParkingSlot.classed('reserved-fill-color', false);
                SVGParkingSlot.classed('cian-blue-fill-color', true);
              } else if (!parkingSlot.favourites_count) {
                this.makeParkingSlotSelectable(SVGParkingSlot, parkingSlotNumber);
              }
            }
          } else {
            SVGParkingSlot.on('dblclick', null);
            parkingSlotNumber.on('dblclick', null);
          }

          // handle private users
          if (parkingSlot.private_user) {
            this.placePrivateUserIconToSpot(svgElement, SVGParkingSlot, slotNumber);
          }

          // handle special flags
          if (parkingSlot.flags?.length) {
            this.placeSpecialFlagIconToSpot(svgElement, SVGParkingSlot, slotNumber);
          }

          if (parkingSlot.reservation) {
            this.updateReservationPlace();
          }
        }
      }
    }
  }

  handleGarageUserClick(
    parkingSlot: ParkingSlot,
    SVGParkingSlot: d3Selection<any>,
    svgRectGElement: d3Selection<SVGGElement>,
    slotNumber: number
  ): void {
    if (this.reservationStatus?.parking_slot?.slot_number !== parkingSlot.slot_number) {
      const parkingSlotNode = SVGParkingSlot.node() as HTMLElement;

      if (parkingSlot.flags?.length) {
        this.tooltipParkingSlotInfo.flags = parkingSlot.flags;
      } else {
        this.tooltipParkingSlotInfo.flags = null;
      }

      if (parkingSlot.private_user) {
        this.tooltipParkingSlotInfo.private_user = parkingSlot.private_user;
      } else {
        this.tooltipParkingSlotInfo.private_user = null;
      }

      if (parkingSlot.slot_number === this.selectedInfoParkingSlot) {
        this.hideInfoTooltip();
        return;
      }

      this.setSelectedInfoParkingSlot(parkingSlotNode, slotNumber);

      if (parkingSlot.flags?.length || parkingSlot.private_user) {
        this.showTooltip(null, this.tooltipParkingSlotInfo, this.selectedInfoElement);
      } else {
        this.hideInfoTooltip();
      }
    }
  }

  private setSelectedInfoParkingSlot(selected: HTMLElement, place: number) {
    this.selectedInfoElement = selected;
    this.selectedInfoParkingSlot = place;
  }

  private hideInfoTooltip(removeSelectedElement = true) {
    this.hideTooltip(this.tooltipParkingSlotInfo, false);

    if (removeSelectedElement) {
      this.setSelectedInfoParkingSlot(null, null);
    }
  }

  private noMatchingSpecialFlags(parkingSlot: ParkingSlot): boolean {
    if (!parkingSlot.flags?.length) {
      return false;
    }

    let userDontHaveFlag = true;

    for (const flag of parkingSlot.flags) {
      if (this.currentUserFlagIds[flag.id]) {
        userDontHaveFlag = false;
        break;
      }
    }

    return userDontHaveFlag;
  }

  makeParkingSlotSelectable(SVGParkingSlot: d3Selection<any>, parkingSlotNumber: d3Selection<any>) {
    if (this.deviceService.isDesktop()) {
      SVGParkingSlot.classed('park-hover', true);

      SVGParkingSlot.on('dblclick', () => {
        this.handleParkingSlotDblClick(SVGParkingSlot.node());
      });

      SVGParkingSlot.on('mouseover', () => {
        this.showTooltip();
      });

      parkingSlotNumber
        .style('cursor', 'pointer')
        .on('mouseover', () => {
          SVGParkingSlot.style('opacity', '1');
        })
        .on('mouseout', () => {
          SVGParkingSlot.style('opacity', '0.5');
        })
        .on('dblclick', () => {
          this.handleParkingSlotDblClick(SVGParkingSlot.node());
        });
    } else {
      SVGParkingSlot.on('touchstart', () => {
        this.handleParkingSlotTouch(SVGParkingSlot);
      });

      parkingSlotNumber.on('touchstart', () => {
        this.handleParkingSlotTouch(SVGParkingSlot);
      });
    }
  }

  handleParkingSlotDblClick(element: HTMLElement) {
    if (this.favouriteSelectMode) {
      this.addFavourite(element);
    } else if (this.userWithBike) {
      this.showUserWithBikeConfirmModal();
    } else if (this.reservationStatus.user.restricted_at) {
      this.showReservationSuspendedModal();
    } else {
      this.openSelectParkingPlaceModal(element);
    }
  }

  handleHammerJSDoubleClick(selected: HTMLElement) {
    let parkingSlot = d3.select(selected).datum() as ParkingSlot;

    if (
      parkingSlot.type === CONSTS.PARKING_SLOT_TYPES.NORMAL &&
      (!parkingSlot.reservation_count || this.favouriteSelectMode)
    ) {
      this.handleParkingSlotDblClick(selected);
    }
  }

  handleParkingSlotTouch(SVGParkingSlot: d3Selection<any>) {
    let opacity = SVGParkingSlot.style('opacity');

    if (this.hoveredSlotNumber && this.hoveredSlotNumber !== SVGParkingSlot) {
      this.hoveredSlotNumber.style('opacity', '0.5');
    }

    if (opacity === '0.5') {
      this.hoveredSlotNumber = SVGParkingSlot;
    } else {
      this.hoveredSlotNumber = null;
    }

    SVGParkingSlot.style('opacity', opacity === '1' ? '0.5' : '1');
  }

  resetParkingPlace(SVGParkingSlot: d3Selection<any>, parkingSlotNumber: d3Selection<any>) {
    this.setElementColorClass(SVGParkingSlot, null);
    SVGParkingSlot.classed('park-hover', false);
    if (this.deviceService.isDesktop()) {
      SVGParkingSlot.on('dblclick', null);
      parkingSlotNumber.on('dblclick', null);
      parkingSlotNumber.style('cursor', 'default').on('mouseover', null).on('mouseout', null).on('dblclick', null);
    } else {
      SVGParkingSlot.on('touchstart', null);
      parkingSlotNumber.on('touchstart', null);
    }
  }

  addTimers() {
    // refresh reservationStatus for map intervally
    timer(CONSTS.WS_REFRESH_RESERVATION_CALL_INTERVAL, CONSTS.WS_REFRESH_RESERVATION_CALL_INTERVAL)
      .pipe(takeWhile(() => this.refreshData))
      .subscribe(() => {
        this.parkPlaceSelectGarageUserService.getCurrentReservation(false, true).subscribe();
      });

    // refresh parking slots for map intervally
    timer(CONSTS.WS_REFRESH_PARKING_SLOTS_DELAY, CONSTS.WS_REFRESH_PARKING_SLOTS_CALL_INTERVAL)
      .pipe(takeWhile(() => this.refreshData))
      .subscribe(() => {
        this.updateUserMap(true);
      });
  }

  getGarageUserParkingSlots(callback: (parkingSlots: ParkingSlot[]) => void, ignoreError: boolean) {
    this.parkPlaceSelectGarageUserService
      .getParkingSlots(this.floorNumber, ignoreError)
      .pipe(first())
      .subscribe({
        next: (result: ParkingSlotResponse) => {
          this.freePlaceCount = result.freePlaceCount;
          callback(result.parkingSlots);
        },
        error: () => {
          callback([]);
        },
      });
  }

  updateReservationPlace() {
    if (this.reservationStatus && this.reservationStatus.parking_slot) {
      let slotNumber = this.reservationStatus.parking_slot.slot_number;
      let SVGParkingSlot = this.d3RectGElement.select("[data-name='" + slotNumber + "']");

      if (!SVGParkingSlot.empty()) {
        this.setSelectedParkingSlot(SVGParkingSlot.node() as HTMLElement, slotNumber);
      } else {
        this.setSelectedParkingSlot(null, null);
      }
    }

    if (this.selectedElement) {
      let selectedSVGElement = d3.select(this.selectedElement);
      if (!selectedSVGElement.empty()) {
        // set color of reserved parking place!
        if (this.reservationStatus && this.reservationStatus.exist) {
          if (this.reservationStatus.final) {
            this.setElementColorClass(selectedSVGElement, 'cian-blue-fill-color');
          } else {
            this.setElementColorClass(selectedSVGElement, 'yellow-fill-color');
          }

          this.showTooltip(this.reservationStatus.expiration_formatted);
          this.hideInfoTooltip();
        } else {
          this.setElementColorClass(selectedSVGElement, 'dark-green-fill-color');
          this.hideTooltip();
        }
      } else {
        this.hideTooltip();
      }
    } else {
      this.hideTooltip();
    }
  }

  openSelectParkingPlaceModal(selected: HTMLElement) {
    if (this.reservationStatus && (!this.reservationStatus.exist || this.reservationStatus.changeParkingSlot)) {
      let place = d3.select(selected).attr('data-name');
      let floorPlace = new FloorPlace(this.floorNumber, parseInt(place));

      let modalConfig;

      if (this.reservationStatus.changeParkingSlot) {
        modalConfig = CONSTS.MODAL.SWAP_PICK_PARKING_PLACE;
      } else {
        if (!this.reservationStatus.user.in_garage) {
          modalConfig = CONSTS.MODAL.PICK_PARKING_PLACE;
        } else {
          modalConfig = CONSTS.MODAL.PICK_PARKING_PLACE_IN_GARAGE;
        }
      }

      let config = new ModalConfig(null, modalConfig);
      config.floorPlace = floorPlace;

      this.modalService.openConfirmModal(config).then(
        () => {
          this.setSelectedParkingSlot(selected, parseInt(place));
          this.reserveParkingSlot();
        },
        () => {
          // reject ignored
        }
      );
    } else {
      let floorPlace = new FloorPlace(
        this.reservationStatus.parking_slot.floor,
        this.reservationStatus.parking_slot.slot_number
      );
      let config = new ModalConfig(null, CONSTS.MODAL.ALREADY_HAVE_RESERVATION);
      config.floorPlace = floorPlace;

      this.modalService.openConfirmModal(config).then(
        (result) => {
          if (result.jump_to_floor) {
            this.router.navigateByUrl(CONSTS.PAGE_URL.FLOOR[config.floorPlace.floor]);
          }
        },
        () => {
          // reject ignored
        }
      );
    }
  }

  extendReservation() {
    if (!this.reservationStatus.expiration_extended) {
      this.modalService
        .openConfirmModal(new ModalConfig(CONSTS.ICON_URL.EXTEND_COUNTER, CONSTS.MODAL.EXTEND_COUNTER))
        .then(
          () => {
            this.parkPlaceSelectGarageUserService.extendReservation().subscribe();
          },
          () => {
            // reject ignored
          }
        );
    }
  }

  reserveParkingSlot() {
    if (this.reservationStatus.changeParkingSlot) {
      this.parkPlaceSelectGarageUserService.changeParkingSlot(this.selectedParkingSlot).subscribe({
        next: () => {
          this.reservationStatus.changeParkingSlot = false;
          this.parkPlaceSelectGarageUserService.setReservationStatus(this.reservationStatus);
        },
        error: () => {
          this.updateUserMap();
        },
      });
    } else {
      this.parkPlaceSelectGarageUserService.reserveParkingSlot(this.selectedParkingSlot).subscribe({
        error: () => {
          this.updateUserMap();
        },
      });
    }
  }

  inGarage() {
    window.open(
      this.backend_url + CONSTS.API_END_POINT.USER + '/' + this.reservationStatus.user.id + '/in-garage',
      '_blank'
    );
  }

  leftGarage() {
    window.open(
      this.backend_url + CONSTS.API_END_POINT.USER + '/' + this.reservationStatus.user.id + '/leave',
      '_blank'
    );
  }

  showUserWithBikeConfirmModal() {
    let config = new ModalConfig(CONSTS.ICON_URL.BICYCLE_YELLOW, CONSTS.MODAL.CANT_RESERVE_IN_BIKE_MODE);

    this.modalService.openConfirmModal(config);
  }

  showReservationSuspendedModal() {
    let config = new ModalConfig(CONSTS.ICON_URL.THEY_TOOK_MY_PLACE, CONSTS.MODAL.RESERVATION_SUSPENDED);

    this.modalService.openConfirmModal(config);
  }

  addFavourite(selected: HTMLElement) {
    const place = d3.select(selected).attr('data-name');
    let floorPlace = new FloorPlace(this.floorNumber, parseInt(place));

    let config = new ModalConfig(null, CONSTS.MODAL.PICK_FAVOURITE_PLACE);
    config.floorPlace = floorPlace;

    this.modalService.openConfirmModal(config).then(
      () => {
        this.favouriteService.addFavourite(place).subscribe(() => {
          this.router.navigate([CONSTS.PAGE_URL.FLOOR_SELECT]);
        });
      },
      () => {
        // reject ignored
      }
    );
  }

  stopMap() {
    this.refreshData = false;
    if (this.zoomSub) {
      this.zoomSub.unsubscribe();
    }

    if (this.reservationStatusSub) {
      this.reservationStatusSub.unsubscribe();
    }
    if (this.refreshParkingSlotSub) {
      this.refreshParkingSlotSub.unsubscribe();
    }
    if (this.userWithBikeSub) {
      this.userWithBikeSub.unsubscribe();
    }
    if (this.favouriteSelectSub) {
      this.favouriteSelectSub.unsubscribe();
    }

    this.mapStarted = false;
    this.reservationStatus = undefined;
  }

  ngOnDestroy(): void {
    this.stopMap();
    this.destroyCommon();
  }
}
