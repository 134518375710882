import { Component, OnInit } from '@angular/core';
import { CONSTS } from '../../constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  receptionImage: Object;
  show = false;

  ngOnInit() {
    this.receptionImage = {
      text: 'reception<br /><div>' + CONSTS.RECEPTION_PHONE_NUMBER + '</div>',
      link: 'tel:' + CONSTS.RECEPTION_PHONE_NUMBER,
    };

    setTimeout(() => {
      this.show = true;
    }, 500);
  }
}
