import { Injectable, TemplateRef } from '@angular/core';
import { ConfirmModalComponent } from '../modules/shared/components/confirm-modal/confirm-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from '../modules/shared/components/alert-modal/alert-modal.component';
import { ModalConfig } from '../modules/shared/classes/ModalConfig';
import { DeviceService } from './device.service';
import { CONSTS } from '../constants';
import { Helper } from '../helper';

@Injectable()
export class ModalService {
  isMobile: boolean;

  private alertModalRef: NgbModalRef;
  private customModalRef: NgbModalRef;

  constructor(private modalService: NgbModal, private deviceService: DeviceService) {
    this.isMobile = this.deviceService.isMobile();
  }

  openConfirmModal(modalConfig: ModalConfig, customClass = '') {
    let windowClass = customClass;
    if (this.isMobile) {
      windowClass += ' dark-modal mobile ';
    } else if (modalConfig.floorPlace) {
      windowClass += ' dark-modal animated fadeIn ';
    }

    const modalRef = this.modalService.open(ConfirmModalComponent, {
      windowClass: windowClass,
      backdrop: 'static',
    });
    modalRef.componentInstance.modalConfig = modalConfig;

    return modalRef.result;
  }

  openAlertModal(message: string, detail: string = null, imageURL = '/assets/images/icon_TheyTookMyPlace.svg') {
    let windowClass = '';
    if (this.isMobile) {
      windowClass += 'dark-modal mobile';
    }

    let translated = CONSTS.ERROR_MESSAGES[message];

    // allow only one alert modal opened at once
    if (this.alertModalRef) {
      this.alertModalRef.dismiss();
    }

    this.alertModalRef = this.modalService.open(AlertModalComponent, {
      windowClass: windowClass,
      backdrop: 'static',
    });
    this.alertModalRef.componentInstance.imageURL = imageURL;
    this.alertModalRef.componentInstance.message = translated ? translated : message;
    this.alertModalRef.componentInstance.detail = detail;
  }

  openCustomModal(modalComponent: any, data: any = null, customClass = '') {
    let windowClass = customClass;
    if (this.isMobile) {
      windowClass += ' dark-modal mobile';
    }

    this.customModalRef = this.modalService.open(modalComponent, {
      windowClass: windowClass,
      backdrop: 'static',
    });
    this.customModalRef.componentInstance.data = data;

    return this.customModalRef.result;
  }

  openFromTemplate(template: TemplateRef<any>, config = {}) {
    return this.modalService.open(template, config).result;
  }

  checkHungarianPlateNumber(plateNumber: string) {
    return new Promise((resolve, reject) => {
      if (Helper.validateByRegex(plateNumber, CONSTS.PATTERNS.PLATE_NUMBER_HUNGARY)) {
        resolve(null);
      } else {
        this.openConfirmModal(
          new ModalConfig(CONSTS.ICON_URL.THEY_TOOK_MY_PLACE, CONSTS.MODAL.NOT_HUNGARIAN_PLATE),
          'check_lpn'
        ).then(
          () => {
            resolve(null);
          },
          () => {
            reject();
          }
        );
      }
    });
  }

  closeCustomModal() {
    if (this.customModalRef) {
      this.customModalRef.dismiss();
    }
  }
}
