<div title="select floor -{{ floorNumber }}"
     class="floor-selector hover-bigger-small"
     [ngClass]="{ mobile: isMobile }"
     (click)="selectFloor(floorNumber)"
>
    <div class="floor-number">
        Floor
        <span class="number-circle">
          <span class="number-content"> -{{ floorNumber }} </span>
        </span>
    </div>
    <div class="floor-content">
        <app-loader [isFullScreen]="false" [showLoader]="showLoader"></app-loader>
        <div *ngIf="!showLoader" class="aligner">
            <div class="free-place-count">{{ freePlaceCount }}</div>
            <div class="free-place-count-text">free places</div>
        </div>
    </div>
</div>
<div (click)="disableClick($event)" class="floor-background"></div>
