<app-modal-header [activeModal]="activeModal"></app-modal-header>

<form #specialFlagForm="ngForm">
    <div class="modal-body">
        <div class="row form-row">
            <div class="col-12">
                <div class="form-group form-title">
                    {{ editMode ? "Edit special flag" : "Add special flag" }}
                </div>
            </div>

            <div class="col-12 mb-4">
                <div class="form-group">
                    <input [ngClass]="{
                                    'has-error':
                                     isEmpty(tmpFlag.name) &&
                                     (name.dirty || name.touched || saveClicked)
                                }"
                           #name="ngModel"
                           [required]="true"
                           class="form-control"
                           type="text"
                           name="name"
                           placeholder="Add flag name here"
                           required
                           [(ngModel)]="tmpFlag.name"
                    />
                </div>
            </div>

            <div class="col-12">
                <div class="form-group d-flex file-input"
                     [ngClass]="{'has-error-bold': isEmpty(flagIconName) && saveClicked}">

                    <button
                        *ngIf="!tmpFlag.icon"
                        class="file-upload-input-button upload-file-button"
                        [textContent]="'Add file'"
                        (click)='fileInput.click()'>
                    </button>

                    <input #icon="ngModel"
                           class="form-control file-text-input"
                           type="text"
                           name="icon"
                           disabled="true"
                           placeholder="only 32x32px .png files"
                           [class.pl-0]="!flagIconName"
                           [(ngModel)]="flagIconName"/>

                    <button
                        *ngIf="tmpFlag.icon"
                        (click)="deleteFile()"
                        class="file-upload-input-button delete-file-button">

                        <img [src]="'/assets/images/close.svg'"
                             alt="close">
                    </button>

                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <div (click)="activeModal.dismiss()" class="modal-button-link">
            <a>CANCEL</a>
        </div>
        <div (click)="saveSpecialFlag(specialFlagForm.valid)" class="modal-button-link">
            <a>SAVE</a>
        </div>
    </div>
</form>

<input
    hidden
    [multiple]="false"
    #fileInput
    (change)='onFileChange($event); fileInput.value = "";'
    type='file'
    [accept]="'image/png'"/>
