<div class="select-toggle">
    <app-dropdown-list
        [disabled]="disabled"
        [hasResetButton]="true"
        (resetClicked)="deleteCurrentValue()"
        [elementList]="freeParkingSlots"
        [selectedElements]="[selectedParkingSlot]"
        (elementClicked)="selectParkingSlot($event)"
        [title]="selectTitle"
        [labelKey]="'slot_number'">
    </app-dropdown-list>
</div>
