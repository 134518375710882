import { GridOptions, ICellRendererParams } from 'ag-grid-community';
import { LogTableCommonColumns } from '../classes/LogTableCommonColumns';
import { LogTableComponent } from '../components/log-table/log-table.component';
import { LogTableConfigUtil } from '../../../classes/LogTableConfigUtil';
import { CardSync } from '../classes/Log';
import * as moment from 'moment/moment';

export class CardSyncLogTableConfig {
  static getGridOptions(instance: LogTableComponent): GridOptions {
    return {
      ...LogTableConfigUtil.getCommonOptions<CardSync>(instance),
      columnDefs: LogTableCommonColumns.concat([
        {
          headerName: 'Card',
          field: 'card',
          resizable: true,
          sortable: true,
          width: 200,
        },
        {
          headerName: 'Time',
          field: 'created_at',
          resizable: true,
          sortable: true,
          width: 200,
          suppressSizeToFit: true,
          cellRenderer: (params: ICellRendererParams) => {
            return moment(params.value).format('MM-DD HH:mm');
          },
        },
      ]),
    };
  }
}
