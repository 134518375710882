import { FloorPlace } from './FloorPlace';
import { ModalConfigConsts } from './ModalConfigConsts';

export class ModalConfig {
  imageURL: string;
  title: string;
  titleText: string;

  subTitle: string;
  subTitleClass: string;
  text: string;

  floorPlace: FloorPlace;
  showWithBicycle: boolean;

  licencePlateNumber: string;
  confirmButtonText: string;
  cancelButtonText: string;
  footerCustomClass: string;
  parkingSlotCustomClass: string;
  confirmButtonClass: string;

  plateNumberInput: boolean;
  textInput: boolean;

  disableCancelButton: boolean;
  jumpToParkingPlaceLink: boolean;

  constructor(imageURL: string, modalConfig: ModalConfigConsts) {
    this.imageURL = imageURL;
    this.title = modalConfig['TITLE'];
    this.titleText = modalConfig['TITLE_TEXT'];
    this.subTitle = modalConfig['SUB_TITLE'];
    this.subTitleClass = modalConfig['SUB_TITLE_CLASS'];

    this.text = modalConfig['TEXT'];
    this.confirmButtonText = modalConfig['CONFIRM_BUTTON_TEXT'];
    this.cancelButtonText = modalConfig['CANCEL_BUTTON_TEXT'];
    this.licencePlateNumber = modalConfig['LICENCE_PLATE_NUMBER'];

    this.footerCustomClass = modalConfig['FOOTER_CUSTOM_CLASS'];
    this.parkingSlotCustomClass = modalConfig['PARKING_SLOT_CUSTOM_CLASS'];
    this.confirmButtonClass = modalConfig['CONFIRM_BUTTON_CLASS'];

    this.plateNumberInput = modalConfig['PLATE_NUMBER_INPUT'];
    this.textInput = modalConfig['TEXT_INPUT'];

    this.disableCancelButton = modalConfig['DISABLE_CANCEL_BUTTON'];
    this.jumpToParkingPlaceLink = modalConfig['JUMP_TO_PARKING_PLACE_LINK'];

    this.showWithBicycle = modalConfig['SHOW_WITH_BICYCLE'];
  }
}
