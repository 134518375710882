import { Component, OnInit } from '@angular/core';
import { DeviceService } from '../../services/device.service';

@Component({
  selector: 'app-park-place-select',
  templateUrl: './park-place-select.component.html',
  styleUrls: ['./park-place-select.component.scss'],
})
export class ParkPlaceSelectComponent implements OnInit {
  isMobile: boolean;

  constructor(private deviceService: DeviceService) {}

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
  }
}
