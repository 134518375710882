import { LicencePlateNumber } from '../../../classes/LicencePlateNumber';
import { Role } from '../../../classes/Role';
import { User } from '../../../classes/User';
import { SpecialFlag } from '../../../classes/SpecialFlag';

export class UserEditFormData {
  id: number;
  email: string;
  name: string;
  phone: string;

  licence_plate_numbers: Array<LicencePlateNumber>;
  roles: Array<Role>;
  flags?: SpecialFlag[];

  card_number: string;

  assistant_email?: string;
  private_parking_slot_number?: number;

  constructor(user: User = new User()) {
    this.id = user.id;
    this.email = user.email;
    this.name = user.name;
    this.phone = user.phone;
    this.licence_plate_numbers = user.licence_plate_numbers;
    this.roles = user.roles;
    this.card_number = user.card_number;
    this.flags = user.flags;
    this.assistant_email = user.assistant_email;
    this.private_parking_slot_number = user.private_parking_slot_number;
  }
}
