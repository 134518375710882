import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../services/http.service';
import { CONSTS } from '../../constants';
import { User } from '../../classes/User';
import { UserResetPasswordData } from '../login/classes/UserResetPasswordData';
import { Role } from '../../classes/Role';
import { UserResetPasswordFormData } from '../login/classes/user-reset-password-form-data';

@Injectable()
export class AuthService {
  currentUser: User;
  authToken: string;
  tokenExpired: boolean = false;
  permissionDeniedMessage: string = null;

  constructor(private httpService: HttpService) {
    this.currentUser = null;
  }

  // register(data): Observable<any> {
  // return this.httpService.post(CONSTS.API_END_POINT.EMPLOYEE, data, false);
  // }

  login<T>(userFormData: T, endpoint: string): Observable<any> {
    let that = this;

    return this.httpService.post(CONSTS.API_END_POINT.USER + '/' + endpoint, userFormData).pipe(
      tap((result) => {
        if (result.token) {
          that.setUser(result.user, result.roles);
          that.setAuth(result.token);
        }
      })
    );
  }

  sendFirebaseMessagingDeviceToken(token: string) {
    const deviceToken = {
      deviceToken: token,
    };

    return this.httpService.post(`${CONSTS.API_END_POINT.USER}/fcm-token`, deviceToken);
  }

  changePassword(passwordData: UserResetPasswordData) {
    let data = {
      password: passwordData.password,
    };

    return this.httpService.post(CONSTS.API_END_POINT.USER + '/current/change-password', data).pipe(
      tap((result) => {
        this.setUser(result.user, result.user.roles);
      })
    );
  }

  resetPasswordGenerateToken(userEmail: UserResetPasswordFormData) {
    let data = {
      email: userEmail.email,
    };

    return this.httpService.post(CONSTS.API_END_POINT.USER + '/reset-password/generate-token', data);
  }

  resetPassword(userToken: string) {
    return this.httpService.post(`${CONSTS.API_END_POINT.USER}/reset-password/reset?token=${userToken}`, '');
  }

  loadUser() {
    return this.httpService.get(CONSTS.API_END_POINT.USER + '/current');
  }

  updateUser(user: User) {
    if (this.currentUser) {
      this.currentUser.bike = user.bike;
    }
  }

  getCurrentUser() {
    return this.currentUser;
  }

  get authenticated(): boolean {
    return this.currentUser !== null && this.currentUser.status && !this.currentUser.password_is_temporary;
  }

  get isAdmin(): boolean {
    return this.currentUser ? this.currentUser.role === CONSTS.ROLE.ADMIN : false;
  }

  isAdminRole() {
    return this.currentUser ? this.currentUser.role === CONSTS.ROLE.ADMIN : false;
  }

  isAdminOrReceptionistRole() {
    return this.currentUser ? this.currentUser.role === CONSTS.ROLE.ADMIN : false;
  }

  get isReceptionist(): boolean {
    return this.currentUser ? this.currentUser.role === CONSTS.ROLE.RECEPTIONIST : false;
  }

  get isAdminOrReceptionist(): boolean {
    return this.isAdmin || this.isReceptionist;
  }

  get isGarageUser(): boolean {
    return this.currentUser ? this.currentUser.role === CONSTS.ROLE.USER : false;
  }

  logout(): void {
    this.removeAuth();
  }

  setAuth(token: string) {
    localStorage.setItem('auth-token', token);
    this.authToken = token;
  }

  setUser(user: User, roles: Role[]) {
    if (user && roles && roles.length > 0) {
      this.currentUser = user;
      this.currentUser.role = roles[0].slug;

      console.log('logged in user: ', this.currentUser);
    }
  }

  removeAuth() {
    this.currentUser = null;
    localStorage.removeItem('auth-token');
    this.authToken = null;
  }

  checkAuth(): Promise<boolean> {
    let token = localStorage.getItem('auth-token');
    let that = this;

    return new Promise((resolve) => {
      if (token) {
        that.setAuth(token);

        that.loadUser().subscribe({
          next: (userData) => {
            that.setUser(userData.user, userData.roles);
            resolve(true);
          },
          error: () => {
            that.logout();
            resolve(true);
          },
        });
      } else {
        that.logout();
        resolve(true);
      }
    });
  }
}
