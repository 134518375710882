import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { Router } from '@angular/router';
import { CONSTS } from '../../../../constants';
import { ModalService } from '../../../../services/modal.service';
import { LoaderService } from '../../../../services/loader.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit, AfterViewInit {
  showResetPasswordForm: boolean = false;

  CONSTS = CONSTS;

  isUserLoginPage: boolean;
  isUserAdminLoginPage: boolean;
  isResetPasswordCallPage: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private loaderService: LoaderService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.isUserLoginPage = this.router.url === CONSTS.PAGE_URL.LOGIN;
    this.isUserAdminLoginPage = this.router.url === CONSTS.PAGE_URL.ADMIN_LOGIN;
    this.isResetPasswordCallPage = this.router.url.startsWith(CONSTS.PAGE_URL.RESET_PASSWORD);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.authService.tokenExpired) {
        this.modalService.openAlertModal('login_expired');

        this.authService.tokenExpired = false;
      } else if (this.authService.permissionDeniedMessage) {
        this.modalService.openAlertModal(this.authService.permissionDeniedMessage);
        this.authService.permissionDeniedMessage = null;
      }
    }, 500);
  }

  backToLoginPage() {
    this.showResetPasswordForm = false;
  }

  switchToResetPasswordForm() {
    this.showResetPasswordForm = true;
  }

  get loggedInUser() {
    return this.authService.getCurrentUser();
  }
}
