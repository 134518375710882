import { AfterViewInit, Component } from '@angular/core';
import { MapChartAdminComponent } from '../map-chart/map-chart-admin/map-chart-admin.component';

@Component({
  selector: 'app-map-chart-floor-1-admin',
  templateUrl: './map-chart-floor-1.component.html',
  styleUrls: ['../map-chart/map-chart-common/map-chart-common.component.scss'],
})
export class MapChartFloor1AdminComponent extends MapChartAdminComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    this.init();
  }
}
